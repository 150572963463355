import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Manufacturing.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import Pallet from "../../../images/logo/company/manufacturing/pallet.png";
import Pi_1 from "../../../images/manufacturing/palletindia/pi_1.jpg";
import Pi_2 from "../../../images/manufacturing/palletindia/pi_2.jpg";
import Pi_3 from "../../../images/manufacturing/palletindia/pi_3.jpg";
import Pallet_1 from "../../../images/manufacturing/palletindia/pallet_1.jpg";
import Pallet_2 from "../../../images/manufacturing/palletindia/pallet_2.jpg";
import Pallet_3 from "../../../images/manufacturing/palletindia/pallet_3.jpg";
import PinePallet_1 from "../../../images/manufacturing/palletindia/pin-pal_1.jpg";
import PinePallet_2 from "../../../images/manufacturing/palletindia/pin-pal_2.jpg";
import PinePallet_3 from "../../../images/manufacturing/palletindia/pin-pal_3.jpg";
import Pine_1 from "../../../images/manufacturing/palletindia/pin_1.jpg";
import Pine_2 from "../../../images/manufacturing/palletindia/pin_2.jpg";
import Pine_3 from "../../../images/manufacturing/palletindia/pin_3.jpg";
import Pine_4 from "../../../images/manufacturing/palletindia/pin_4.jpg";
import Crate_1 from "../../../images/manufacturing/palletindia/crate_1.jpg";
import Crate_2 from "../../../images/manufacturing/palletindia/crate_2.jpg";
import Crate_3 from "../../../images/manufacturing/palletindia/crate_3.jpg";
import Box_1 from "../../../images/manufacturing/palletindia/box_1.jpg";
import Box_2 from "../../../images/manufacturing/palletindia/box_2.jpg";
import Box_3 from "../../../images/manufacturing/palletindia/box_3.jpg";
import Team from "../../../images/icons/manufacturing/team.svg";
import Mission from "../../../images/icons/manufacturing/mission.svg";
import Quality from "../../../images/icons/manufacturing/quality.svg";

// End of Image Imports

function PalletIndia() {
  const countryList = [
    { name: "Argentina", year: "June 2005" },
    { name: "Bolivia", year: "July 2005" },
    { name: "Canada", year: "Sept 2005" },
    { name: "China", year: "Jan 2006" },
    { name: "Costa Rico", year: "Jan 2005" },
    { name: "Egypt", year: "Oct 2004" },
    { name: "Guntamala", year: "2004" },
    { name: "Mexico", year: "Mar 2003" },
    { name: "Nigeria", year: "Mar 2005" },
    { name: "Philippines", year: "Mar 2005" },
    { name: "Switzerland", year: "Mar 2005" },
    { name: "Turkey", year: "Jan 2006" },
    { name: "Venezuela", year: "Jan 2006" },
    { name: "Australia", year: "Sept 2004" },
    { name: "Brazil", year: "No date as yet" },
    { name: "Chile", year: "June 2005" },
    { name: "Colombia", year: "Sept 2005" },
    { name: "Ecuador", year: "June 2004" },
    { name: "EU", year: "Mar 2004" },
    { name: "India", year: "Sept 2005" },
    { name: "New Zealand", year: "2003" },
    { name: "Peru", year: "Mar 2005" },
    { name: "South Africa", year: "2005" },
    { name: "South Korea", year: "Jun 2005" },
    { name: "US", year: "Sept 2005" },
  ];

  const palletOptions = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Pallet India | Manufacturing - Southern Packaging`;
  }, []);
  return (
    <section className="manufacturing-common-wrap">
      <div className="manufacturing-section section-padding">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between header-wrap">
            <h3 className="section-header">
              Pallet India<span> - Pallets and Boxes</span>
            </h3>
            <img src={Pallet} width={220} alt="Pallet" />
          </div>
          <div className="manufacturing-slider-wrap">
            <OwlCarousel className="owl-theme" {...palletOptions}>
              <div className="item">
                <img src={Pi_1} alt="Slider" />
              </div>
              <div className="item">
                <img src={Pi_2} alt="Slider" />
              </div>
              <div className="item">
                <img src={Pi_3} alt="Slider" />
              </div>
            </OwlCarousel>
          </div>
          <div className="srv-text">
            <p>
              Being one stop shop for the superior quality plywood and pinewood
              products, we, at Pallets India (AN ISO 9001:2008 CERTIFIED
              COMPANY) have grown in leaps and bounds in the industry. We have
              marked the presence in the industry by providing ISMP 15 compliant
              packaging solutions that offer freedom from fungus and pests
              mostly eliminating need for fumigation and heat treatment.
            </p>
          </div>
          <div className="divider"></div>
          <h5>Company Profile</h5>
          <p>
            Since 2008, we have been successfully catering to leading industries
            by providing them ISPM 15 compliant high quality plywood and
            pinewood pallets and boxes. With the profound experience and
            expertise of our team we have set a strong foothold in the industry.
          </p>
          <div className="cpro-wrap">
            <Row>
              <Col sm="12" lg="4" md="4">
                <div className="cpro-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Team} alt="Team" />
                    </div>
                    <h6>Team</h6>
                  </div>
                  <p>
                    Today, we are at the apex in the manufacturing of pallets
                    and crates, plywood storage boxes etc. due to the diligent
                    efforts of the qualified team, we posses. Our talented team
                    is well versed with the production and always strives to
                    develop unmatched quality products.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="cpro-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Quality} alt="Quality" />
                    </div>
                    <h6>Quality</h6>
                  </div>
                  <p>
                    Quality of the products sets the benchmark of the company,
                    thus we always endeavor to maintain it. To assure the
                    quality of the products stringent quality tests are
                    conducted at every level of production starting from the
                    procurement of wood to the finished product.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="cpro-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Mission} alt="Mission" />
                    </div>
                    <h6>Our Mission</h6>
                  </div>
                  <p>
                    As we are a client committed entity, our prime mission is to
                    provide utmost level of satisfaction to the eminent clients
                    by providing exceptional quality products.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Products</h5>
          <h6 className="section-subtitle">ISPM-15 Plywood pallets</h6>
          <p>
            As per ISPM 15 standard plywood comes under treated wood category
            and hence does not require any heat treatment or fumigation.
          </p>
          <div className="note-info-wrap">
            <h6 className="section-subtitle">What is ISPM 15?</h6>
            <p>
              The ISPM 15 protocol was started in UK on March 2002 as no bug
              product but this was compromised. So WHO voluntarily adopted this
              new standard in 2003 along with a process called M.B Methyl
              Bromide a chemical for killing pests (but this is very dangerous
              to human health) this was approved by us standard animal and plant
              health inspection services (APHIS) the ht standard was ratified
              and approved by the American lumber standard commission (ALSC) and
              the Following Countries Adopted On the Following Dates.
            </p>
            <p>
              If you have to export any wooden products to any of these
              countries you must have the wood treated and ISPM stamp is a must
              as ht in 000.
            </p>
          </div>
          <div className="divider"></div>
          <h5>Plywood Pallets</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={Pallet_1} alt="Pallets" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Pallet_2} alt="Pallets" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Pallet_3} alt="Pallets" />
              </Col>
            </Row>
            <div className="pi-country-wrap">
              <Row>
                {countryList.map((country) => (
                  <Col sm="12" lg="2" md="3">
                    <div className="pi-coun-item">
                      <h6>{country.name}</h6>
                      <span>{country.year}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div className="divider"></div>
          <h5>Pine Wood Pallets</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={PinePallet_1} alt="Pallets" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={PinePallet_2} alt="Pallets" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={PinePallet_3} alt="Pallets" />
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Pine Wood Boxes</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={Pine_1} alt="Boxes" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Pine_2} alt="Boxes" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Pine_3} alt="Boxes" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Pine_4} alt="Boxes" />
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Pine Wood Crates</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={Crate_1} alt="Crates" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Crate_2} alt="Crates" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Crate_3} alt="Crates" />
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Plywood Boxes</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={Box_1} alt="Boxes" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Box_2} alt="Boxes" />
              </Col>
              <Col sm="12" lg="3" md="3">
                <img src={Box_3} alt="Boxes" />
              </Col>
            </Row>
          </div>
          <Link to="/contact">
            <button className="btn btn-dark mt-5">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default PalletIndia;
