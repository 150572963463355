import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Home.css";
import { productsData } from "../Products/data/data";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import About from "../../../images/about.png";
import Vision from "../../../images/icons/vision.svg";
import Mission from "../../../images/icons/mission.svg";
import Service1 from "../../../images/service_1.svg";
import Service2 from "../../../images/service_2.svg";
import Service3 from "../../../images/service_3.svg";
import Zavenir from "../../../images/logo/company/products/zavenir.png";
import Fromm from "../../../images/logo/company/products/fromm.png";
import ThreeM from "../../../images/logo/company/products/3m.png";
import SealedAir from "../../../images/logo/company/products/sealed_air.png";
import Starcke from "../../../images/logo/company/products/starcke.png";
import Walter from "../../../images/logo/company/products/walter.png";
import Pallet from "../../../images/logo/company/manufacturing/pallet.png";
import Strap from "../../../images/logo/company/manufacturing/strap.png";
import Safe from "../../../images/logo/company/manufacturing/safe.png";
import Southern from "../../../images/logo/company/manufacturing/southern.png";
import Shine from "../../../images/logo/company/manufacturing/shine.png";
import DownloadIcon from "../../../images/icons/download.svg";
import Slide1 from "../../../images/products/homepage-slider/slide1.jpg";
import Slide2 from "../../../images/products/homepage-slider/slide2.jpg";
import Slide3 from "../../../images/products/homepage-slider/slide3.jpg";
import Slide4 from "../../../images/products/homepage-slider/slide4.jpg";
import Brochure from "../../../brochure/SouthernCatalogue.pdf";

// End of Image Imports

function Home() {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    autoplay: true,
    autoplayTimeout: 8000,
    responsiveClass:true,
  };

  const productList = {
    loop: true,
    margin: 10,
    items: 5,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsiveClass:true,
    responsive: {
      200: {
        items: 1,
        nav: false,
        dots: false,
      },
      768: {
        items: 3,
        nav: false,
        dots: false,
      },
      1200: {
        items: 5,
        nav: false,
      }
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Welcome to Southern Packaging - Packaging Solution in Chennai`;
  }, []);

  return (
    <main className="main-home-wrap">
      {/* Homepage */}
      <section className="homepage-hero-section-wrap">
        <div className="homepage-hero-section section-padding">
          <div className="container">
            <Row>
              <Col sm="12" lg="6" md="6">
                <div className="homepage-content">
                  <h2>
                    The <span>Complete Packaging Solution</span> for all your
                    Business needs.
                  </h2>
                  <p>
                    Technology, Expertise and the complete packaging solution
                    for all your packaging needs. We deal with all packaging
                    material, machineries and custom packaging lines as per your
                    business requirement.
                  </p>
                  <Link to="/contact">
                    <button className="btn btn-dark">Contact Us</button>
                  </Link>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="homepage-slider-wrap">
                  <div className="homepage-slider">
                    <OwlCarousel className="owl-theme" {...options}>
                      <div className="item">
                        <img src={Slide1} alt="Slider" />
                      </div>
                      <div className="item">
                        <img src={Slide2} alt="Slider" />
                      </div>
                      <div className="item">
                        <img src={Slide3} alt="Slider" />
                      </div>
                      <div className="item">
                        <img src={Slide4} alt="Slider" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      {/* About us */}
      <section className="homepage-about-section-wrap">
        <div className="homepage-about-content">
          <div className="container">
            <Row>
              <Col
                sm="12"
                lg="5"
                md="5"
                className="d-flex justify-content-center"
              >
                <img src={About} className="hmp-img" alt="About Us" />
              </Col>
              <Col sm="12" lg="7" md="7" className="d-flex align-items-center">
                <div className="hma-content">
                  <h3 className="section-header">
                    About <span>Us</span>
                  </h3>
                  <p>
                    We at Southern Packaging are envisaged to be a one stop
                    packaging store, Providing customers with quality products
                    and service, Always striving to reduce the customers cost by
                    providing innovative solutions and products.
                  </p>
                </div>
              </Col>
            </Row>
            <div className="about-vm-wrap">
              <Row>
                <Col sm="12" lg="6" md="6">
                  <div className="vision-content-wrap">
                    <h4>
                      <img src={Vision} alt="Vision" />
                      Vision
                    </h4>
                    <p>
                      Southern Packaging is envisaged to be a one stop packaging
                      store, providing customers with quality products and
                      service, always striving to reduce the customers packaging
                      cost by providing innovative solutions and products.
                    </p>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="6">
                  <div className="mission-content-wrap yellow">
                    <h4>
                      <img src={Mission} alt="Mission" />
                      Mission
                    </h4>
                    <p>
                      <span>
                        To save you money with high quality, competitively
                        priced products.
                      </span>
                      <span>
                        To make purchasing from us an enjoyable experience
                        through our extensive range of products &amp; superior
                        customer service.
                      </span>
                      <span>
                        To provide you with exceptional sales support – before
                        &amp; after the sale.
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      {/* Homepage About us */}
      <section className="homepage-services-section">
        <div className="homepage-services-content">
          <div className="container">
            <div className="hms-header">
              <h3 className="section-header center">
                Our <span>Services</span>
              </h3>
              <p>
                We at Southern Packaging are envisaged to be a one stop
                packaging store, Providing customers with quality products and
                service, Always striving to reduce the customers cost by
                providing innovative solutions and products.
              </p>
            </div>
            <div className="hm-service-item-wrap">
              <Row>
                <Col sm="12" lg="4" md="4">
                  <div className="hm-service-item">
                    <div className="hms-img">
                      <img src={Service1} alt="Packaging Machines" />
                    </div>
                    <h5>Maintenance and Repairs of Packaging Machines</h5>
                    <p>
                      Annual Maintaince Contract for all Packing machine our
                      trained and experienced service team, undertakes AMC'S for
                      all makes of strapping, taping and Stretch wrapping
                      machines, we also supply spare for all your packing
                      machines.
                    </p>
                    <Link to="/services">
                      <span data-more className="pointer">
                        More
                      </span>
                    </Link>
                  </div>
                </Col>
                <Col sm="12" lg="4" md="4">
                  <div className="hm-service-item">
                    <div className="hms-img">
                      <img src={Service2} alt="Packaging Contracts" />
                    </div>
                    <h5>Complete Packaging Contracts</h5>
                    <p>
                      We undertake complete packing of your products. Our
                      trained work force will do rust preventive treatment, VCI
                      packing, cartoning, palletizing and container loading with
                      lashing. We will use our packaging machine and labour and
                      we will charge you on per component basis.
                    </p>
                    <Link to="/services">
                      <span data-more className="pointer">
                        More
                      </span>
                    </Link>
                  </div>
                </Col>
                <Col sm="12" lg="4" md="4">
                  <div className="hm-service-item">
                    <div className="hms-img">
                      <img
                        src={Service3}
                        alt="Packaging Design and Development"
                      />
                    </div>
                    <h5>Packaging Design and Development</h5>
                    <p>
                      We can help you to design optimum packing for you product
                      which will be as per Internal Standard.
                    </p>
                    <Link to="/services">
                      <span data-more className="pointer">
                        More
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      {/* Homepage Products */}
      <section className="homepage-products-wrap">
        <div className="homepage-products">
          <div className="container">
            <h3 className="section-header center">
              Our <span>Products</span>
            </h3>
            <div className="hm-prods-wrap">
              <a
                href="http://www.daubert.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item lgreen">
                  <img src={Zavenir} alt="Zavenir" />
                </div>
              </a>
              <a
                href="http://www.fromm-pack.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item red">
                  <img src={Fromm} alt="Fromm" />
                </div>
              </a>
              <a
                href="http://www.3mindia.co.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item red">
                  <img src={ThreeM} alt="3M" />
                </div>
              </a>
              <a
                href="http://www.sealedair.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item green">
                  <img src={SealedAir} alt="Sealed Air" />
                </div>
              </a>
              <a
                href="http://www.starcke.de/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item blue">
                  <img src={Starcke} alt="Starcke" />
                </div>
              </a>
              <a
                href="http://www.starcke.de/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item black">
                  <img src={Walter} alt="Walter" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      {/* Homepage Manufacturing */}
      <section className="homepage-products-wrap">
        <div className="homepage-products">
          <div className="container">
            <h3 className="section-header center">
              Our <span>Manufacturing</span>
            </h3>
            <div className="hm-prods-wrap">
              <Link to="/products">
                <div className="hm-prod-item brown">
                  <img src={Pallet} alt="Pallet" />
                </div>
              </Link>
              <Link to="/products">
                <div className="hm-prod-item orange">
                  <img src={Strap} alt="Strap" />
                </div>
              </Link>
              <Link to="/products">
                <div className="hm-prod-item yellow">
                  <img src={Safe} alt="Safe" />
                </div>
              </Link>
              <Link to="/products">
                <div className="hm-prod-item yellow">
                  <img src={Southern} alt="Southern" />
                </div>
              </Link>
              <a
                href="http://www.shinecleaningsolutions.co.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hm-prod-item orange">
                  <img src={Shine} alt="Shine" />
                </div>
              </a>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <a href={Brochure} download>
                <button className="btn btn-dark d-flex align-items-center justify-content-center gap-2">
                  <img src={DownloadIcon} width={23} alt="Download Brochure" />
                  Download Brochure
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      {/* Homepage Product Gallery */}
      <section className="homepage-product-gallery-wrap">
        <div className="homepage-product-gallery">
          <div className="container">
            <h3 className="section-header center">
              Our <span>Product Gallery</span>
            </h3>
            <div className="homepage-product-slider">
              <div className="container">
                <OwlCarousel className="owl-theme" {...productList}>
                  {productsData.map((item) =>
                    item.products.map((product) => (
                      <div className="item pg-item">
                        <div className="pg-img" title={product.name}>
                          <img
                            src={product.imgSmall}
                            alt={`Southern Packaging ${product.name}`}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
