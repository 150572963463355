import React, { useState, useEffect } from "react";

import Logo from "../../../images/logo/logo.png";
// import PreloaderImg from "../../../images/Preloader.gif";

function Preloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2100);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className={`preloader ${loading ? "" : "pre-hide"}`}>
      <div className="pre-progress"></div>
      <div className="preloader-content">
        <img src={Logo} alt="Logo" width={200} />
        {/* <div className="loader"></div> */}
      </div>
    </div>
  );
}

export default Preloader;
