import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Products.css";
import { productsData } from "./data/data";

// Image Imports

// End of Image Imports

function Categories() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Categories - Southern Packaging`;
  }, []);

  return (
    <section className="category-section-wrap">
      <div className="category-section section-padding">
        <div className="container">
          <h3 className="section-header">
            Our <span>Products</span>
          </h3>
          <div className="category-items-wrap">
            <Row>
              {productsData.map((product) => (
                <Col sm="12" lg="3" md="3">
                  <Link
                    to={`/products/${product.url}`}
                  >
                    <div className="cat-item">
                      <img src={product.img} alt={product.title} />
                      <h6>{product.category}</h6>
                      {/* {product.products.length > 0 ? (
                        <span>
                          {product.products.length} Product
                          {product.products.length > 1 ? "s" : ""}
                        </span>
                      ) : (
                        <span>No products available</span>
                      )} */}
                      <button className="btn btn-dark">View All</button>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Categories;
