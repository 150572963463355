import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import GoToTop from "../Common/GoToTop";

// Image Imports

import Logo from "../../../images/logo/logo.png";
import Phone from "../../../images/icons/footer/phone.svg";
import Email from "../../../images/icons/footer/mail.svg";
import Location from "../../../images/icons/footer/location.svg";
import Headset from "../../../images/icons/footer/headset.svg";
import Website from "../../../images/icons/footer/website.svg";
import Youtube from "../../../images/icons/social/youtube.svg";
import WhatsApp from "../../../images/icons/social/whatsapp.svg";
import Facebook from "../../../images/icons/social/facebook-circle.svg";
import Insta from "../../../images/icons/social/instagram.svg";
import Twitter from "../../../images/icons/social/twitter.svg";

// End of Image Imports
function Footer() {
  return (
    <footer>
      <div className="container">
        <Row>
          <Col sm="12" md="6" lg="6">
            <img
              src={Logo}
              className="lazy"
              data-mdb-lazy-src
              width={210}
              alt="Southern Packaging"
            />
            <div className="footer-contact-details">
              <p>
                <a
                  href="https://goo.gl/maps/djnUFLGcZik2Ntrx6"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={Location}
                    alt="Location"
                    className="lazy"
                    data-mdb-lazy-src
                  />
                  Plot No. IV, V & VI, Raja's Garden Phase - II, <br /> Noombal,
                  Chennai - 600 077
                </a>
              </p>
              <p>
                <a href="tel:+919884895147">
                  <img
                    src={Phone}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Phone"
                    style={{ width: "12px", marginLeft: "3px" }}
                  />
                  +91 98848 95147
                </a>
              </p>
              <p>
                <a
                  href="http://api.whatsapp.com/send?phone=919444446611"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Headset}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Call"
                  />
                  (+91) - 89399 92997 / 94444 46611 / 94449 03131 / 94449 03166
                </a>
              </p>
              <p className="d-flex align-items-center gap-2">
                <a href="mailto:ccare@southernpack.com">
                  <img
                    src={Email}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Email"
                  />
                  ccare@southernpack.com
                </a>
              </p>
              <p className="d-flex align-items-center gap-2">
                <a href="https://www.southernpackaging.in">
                  <img
                    src={Website}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Website"
                  />
                  www.southernpackaging.in
                </a>
              </p>
            </div>
          </Col>
          <Col sm="12" md="2" lg="2">
            <h6 data-title>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="2" lg="2">
            <h6 data-title>Legal</h6>
            <ul className="footer-links">
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="2" lg="2">
            <h6 data-title>Social Media</h6>
            <div className="social-links">
              <a
                href="https://www.facebook.com/SouthernPackaging"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={Facebook}
                  className="lazy"
                  width={20}
                  data-mdb-lazy-src
                  alt="Facebook"
                />
              </a>
              <a
                href="https://www.twitter.com/CCare_SP"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={Twitter}
                  className="lazy"
                  width={20}
                  data-mdb-lazy-src
                  alt="Twitter"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCIi7o749qu2TlquyzibBYBg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={Youtube}
                  className="lazy"
                  width={20}
                  data-mdb-lazy-src
                  alt="Dribbble"
                />
              </a>
              <a
                href="https://wa.me/+919884895147"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={WhatsApp}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="WhatsApp"
                />
              </a>
              {/* <a
                href="https://www.instagram.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={Insta}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="Instagram"
                />
              </a> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="copy-text">
        <div className="container">
          <div className="copy-text-content">
            <p>
              2014 - {new Date().getFullYear()} &copy; Southern Packaging | All
              Rights Reserved.
            </p>
            <p>
              Made with ♥ and code by{" "}
              <a
                href="https://devship.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Devship</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <GoToTop />
    </footer>
  );
}

export default Footer;
