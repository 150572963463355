import React from "react";

// Image Imports

import GoTop from "../../../images/icons/footer/top_arrow.svg";

// End of Image Imports

function GoToTop() {
  const GoTopHandler = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="go-top-wrap" onClick={GoTopHandler}>
      <img src={GoTop} alt="Go to Top" />
    </section>
  );
}

export default GoToTop;
