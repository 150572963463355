import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

import "./Legal.css";

// Image Imports

// import PrivacyImg from "../../../images/Privacy.svg";

// End of Image Imports

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Privacy Policy - Southern Packaging";
  }, []);
  return (
    <section className="legal-section container">
      <div className="terms-section section-padding">
        <h3 className="section-header">Privacy Policy</h3>
        <div className="legal-wrap">
          <Row>
            <Col sm="12" lg="12" md="12">
              <p>
                At Southern Packaging, accessible from{" "}
                <a
                  href="http://www.southernpack.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.southernpack.com
                </a>
                , one of our main priorities is the privacy of our visitors.
                This Privacy Policy document contains types of information that
                is collected and recorded by Southern Packaging and how we use it.
              </p>
              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Southern Packaging. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>
              <h5>Consent</h5>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </Col>
          </Row>
          <h5>Information we collect</h5>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information. If you
            contact us directly, we may receive additional information about you
            such as your name, email address, phone number, the contents of the
            message and/or attachments you may send us, and any other
            information you may choose to provide. When you register for an
            Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone
            number.
          </p>
          <h5>How we use your information</h5>
          <span>
            We use the information we collect in various ways, including to:
          </span>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer
            </li>
            <li>
              service, to provide you with updates and other information
              relating to the website, and for
            </li>
            <li>marketing and promotional purposes</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <h5>Log Files</h5>
          <p>
            Southern Packaging follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            as part of hosting services' analytics. The information collected by
            log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic
            information.
          </p>
          <h5>Advertising Partners Privacy Policies</h5>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Southern Packaging. Third-party ad servers or ad
            networks use technologies like cookies, JavaScript, or Web Beacons
            that are used in their respective advertisements and links that
            appear on Southern Packaging, which are sent directly to users' browsers. They
            automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit.
          </p>
          <p>
            Note that Southern Packaging has no access to or control over these cookies
            that are used by third-party advertisers.
          </p>
          <h5>Third Party Privacy Policies</h5>
          <p>
            Southern Packaging's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
          <h5>CCPA Privacy Rights (Do Not Sell My Personal Information)</h5>
          <p>
            Under the CCPA, among other rights, California consumers have the
            right to: Request that a business that collects a consumer's
            personal data disclose the categories and specific pieces of
            personal data that a business has collected about consumers. Request
            that a business delete any personal data about the consumer that a
            business has collected. Request that a business that sells a
            consumer's personal data, not sell the consumer's personal data. If
            you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <hr />
          <p>
            You can contact us via email at{" "}
            <span className="highlight">ccare@southernpack.com</span> for any futher
            queries or clarifications.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Privacy;
