import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";

import "./Common.css";

// Image Imports

import Logo from "../../../images/logo/logo.png";

// End of Image Imports

function Header() {
  const [nav, setNav] = useState(false);

  const noRefCheck = () => {
    setNav(!nav);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <Navbar color="white" expand="md" fixed="top" container light>
        <Link to="/" className="p-0 navbar-brand" onClick={noRefCheck}>
          <img
            src={Logo}
            className="lazy"
            data-mdb-lazy-src
            alt="Southern Packaging"
            width={210}
          />
        </Link>
        <NavbarToggler className="nav-toggler" onClick={noRefCheck} />
        <Collapse
          className={`justify-content-end ${nav ? "d-block" : ""}`}
          navbar
        >
          <Nav className="mt-1 align-items-center" navbar>
            <NavItem>
              <NavLink className={`nav-link`} to="/" onClick={noRefCheck}>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`nav-link`} to="/about" onClick={noRefCheck}>
                About us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/services"
                onClick={noRefCheck}
              >
                Services
              </NavLink>
            </NavItem>
            <NavItem className="custom-dropdown">
              <div className="nav-link custom-link">
                Distribution For
                <ul className="custom-dropdown-menu">
                  <li>Daubert</li>
                  <li>FROMM</li>
                  <li>3M Adhesives</li>
                  <li>Sealed Air - SH</li>
                  <li>Starcke</li>
                  <li>Walter</li>
                </ul>
              </div>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/products"
                onClick={noRefCheck}
              >
                Products
              </NavLink>
            </NavItem>
            <NavItem className="custom-dropdown">
              <NavLink
                to="/manufacturing"
                className="nav-link custom-link"
                onClick={(e) => e.preventDefault()}
              >
                Manufacturing
                <ul className="custom-dropdown-menu large">
                  <Link to="/manufacturing/pallet-india">
                    <li>Pallet India</li>
                  </Link>
                  <Link to="/manufacturing/safe-strapping">
                    <li>Safe Strapping</li>
                  </Link>
                  <Link to="/manufacturing/safe-edge-products">
                    <li>Safe Edge Products</li>
                  </Link>
                  <Link to="/manufacturing/bag-manufacturing-unit">
                    <li>Bag Manufacturing Unit</li>
                  </Link>
                  <a
                    onClick={handleClick}
                    href="http://www.shinecleaningsolutions.co.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li>Shine Cleaning Solutions</li>
                  </a>
                </ul>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/contact"
                onClick={noRefCheck}
              >
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
