// Image Imports

import Consumbales from "../../../../images/products/category/consumables/consumables.png";
import Tools from "../../../../images/products/category/tools/tools.png";
import Zavenir from "../../../../images/products/category/zavenir/zavenir.png";
import FromSealed from "../../../../images/products/category/frommsealed/frommsealed.png";
import Starcke3m from "../../../../images/products/category/starcke3m/starcke3m.png";
import Sbdwalter from "../../../../images/products/category/sbdwalter/sbdwalter.png";
import Spshine from "../../../../images/products/category/spshine/spshine.png";

// Products

// Consumbales
import Angle_small from "../../../../images/products/category/consumables/products/angle_small.png";
import Angle_big from "../../../../images/products/category/consumables/products/angle_big.png";
import Pp_small from "../../../../images/products/category/consumables/products/ppstrap_small.png";
import Pp_big from "../../../../images/products/category/consumables/products/ppstrap_big.png";
import Ply_small from "../../../../images/products/category/consumables/products/plywood_small.jpg";
import Ply_big from "../../../../images/products/category/consumables/products/plywood_big.jpg";
import Nail_small from "../../../../images/products/category/consumables/products/nails_small.png";
import Nail_big from "../../../../images/products/category/consumables/products/nails_big.png";
import Bags_small from "../../../../images/products/category/consumables/products/bags_small.jpg";
import Bags_big from "../../../../images/products/category/consumables/products/bags_big.jpg";
import Seal_small from "../../../../images/products/category/consumables/products/seal_small.png";
import Seal_big from "../../../../images/products/category/consumables/products/seal_big.png";
import Bopp_small from "../../../../images/products/category/consumables/products/bopptape_small.png";
import Bopp_big from "../../../../images/products/category/consumables/products/bopptape_big.png";
import Pet_small from "../../../../images/products/category/consumables/products/petstrap_small.png";
import Pet_big from "../../../../images/products/category/consumables/products/petstrap_big.png";
import Shrink_small from "../../../../images/products/category/consumables/products/shrink_small.png";
import Shrink_big from "../../../../images/products/category/consumables/products/shrink_big.png";
import Foam_small from "../../../../images/products/category/consumables/products/foam_small.png";
import Foam_big from "../../../../images/products/category/consumables/products/foam_big.png";
import SteelStrap_small from "../../../../images/products/category/consumables/products/steelstrap_small.png";
import SteelStrap_big from "../../../../images/products/category/consumables/products/steelstrap_big.png";
import Cord_small from "../../../../images/products/category/consumables/products/cord_small.jpg";
import Cord_big from "../../../../images/products/category/consumables/products/cord_big.jpg";

// Tools
import Nailer_small from "../../../../images/products/category/tools/products/nailer_small.png";
import Nailer_big from "../../../../images/products/category/tools/products/nailer_big.png";
import Autostrapmachine_small from "../../../../images/products/category/tools/products/autostrapmachine_small.png";
import Autostrapmachine_big from "../../../../images/products/category/tools/products/autostrapmachine_big.png";
import Economy_small from "../../../../images/products/category/tools/products/economy_small.png";
import Economy_big from "../../../../images/products/category/tools/products/economy_big.png";
import Sealing_small from "../../../../images/products/category/tools/products/sealing_small.png";
import Sealing_big from "../../../../images/products/category/tools/products/sealing_big.png";
import Manual_small from "../../../../images/products/category/tools/products/manual_small.png";
import Manual_big from "../../../../images/products/category/tools/products/manual_big.png";
import Dispensers_small from "../../../../images/products/category/tools/products/dispensers_small.png";
import Dispensers_big from "../../../../images/products/category/tools/products/dispensers_big.png";
import ShrinkWrap_small from "../../../../images/products/category/tools/products/shrink_small.png";
import ShrinkWrap_big from "../../../../images/products/category/tools/products/shrink_big.png";
import Taping_small from "../../../../images/products/category/tools/products/taping_small.png";
import Taping_big from "../../../../images/products/category/tools/products/taping_big.png";
import Customized_small from "../../../../images/products/category/tools/products/customized_small.png";
import Customized_big from "../../../../images/products/category/tools/products/customized_big.png";

// Zavenir Products
import Ccpp_small from "../../../../images/products/category/zavenir/products/ccpp_small.png";
import Ccpp_big from "../../../../images/products/category/zavenir/products/ccpp_big.png";
import Cutting_small from "../../../../images/products/category/zavenir/products/cutting_small.png";
import Cutting_big from "../../../../images/products/category/zavenir/products/cutting_big.png";
import Water_small from "../../../../images/products/category/zavenir/products/water_small.png";
import Water_big from "../../../../images/products/category/zavenir/products/water_big.png";
import Aqueous_small from "../../../../images/products/category/zavenir/products/aqueous_small.png";
import Aqueous_big from "../../../../images/products/category/zavenir/products/aqueous_big.png";
import Noxrust_small from "../../../../images/products/category/zavenir/products/noxrust_small.png";
import Noxrust_big from "../../../../images/products/category/zavenir/products/noxrust_big.png";
import Evapo_small from "../../../../images/products/category/zavenir/products/evapo_small.png";
import Evapo_big from "../../../../images/products/category/zavenir/products/evapo_big.png";
import Vci_small from "../../../../images/products/category/zavenir/products/vci_small.png";
import Vci_big from "../../../../images/products/category/zavenir/products/vci_big.png";
import Film_small from "../../../../images/products/category/zavenir/products/film_small.png";
import Film_big from "../../../../images/products/category/zavenir/products/film_big.png";
import Emmiter_small from "../../../../images/products/category/zavenir/products/emmiter_small.png";
import Emmiter_big from "../../../../images/products/category/zavenir/products/emmiter_big.png";
import Paint_small from "../../../../images/products/category/zavenir/products/paint_small.png";
import Paint_big from "../../../../images/products/category/zavenir/products/paint_big.png";
import Ppt_small from "../../../../images/products/category/zavenir/products/ppt_small.png";
import Ppt_big from "../../../../images/products/category/zavenir/products/ppt_big.png";
import Strippers_small from "../../../../images/products/category/zavenir/products/strippers_small.png";
import Strippers_big from "../../../../images/products/category/zavenir/products/strippers_big.png";

// FROMM & Sealed Air Products
import Batplastic_small from "../../../../images/products/category/frommsealed/products/batplastic_small.png";
import Batplastic_big from "../../../../images/products/category/frommsealed/products/batplastic_big.png";
import Pneumatic_small from "../../../../images/products/category/frommsealed/products/pneumatic_small.png";
import Pneumatic_big from "../../../../images/products/category/frommsealed/products/pneumatic_big.png";
import SealM_small from "../../../../images/products/category/frommsealed/products/seal_small.png";
import SealM_big from "../../../../images/products/category/frommsealed/products/seal_big.png";
import Pseal_small from "../../../../images/products/category/frommsealed/products/pseal_small.png";
import Ppseal_big from "../../../../images/products/category/frommsealed/products/pseal_big.png";
import Pstretch_small from "../../../../images/products/category/frommsealed/products/pstretch_small.png";
import Pstretch_big from "../../../../images/products/category/frommsealed/products/pstretch_big.png";
import Airpad_small from "../../../../images/products/category/frommsealed/products/airpad_small.png";
import Airpad_big from "../../../../images/products/category/frommsealed/products/airpad_big.png";
import Cryovac_small from "../../../../images/products/category/frommsealed/products/cryovac_small.png";
import Cryovac_big from "../../../../images/products/category/frommsealed/products/cryovac_big.png";
import Cushion_small from "../../../../images/products/category/frommsealed/products/cushion_small.png";
import Cushion_big from "../../../../images/products/category/frommsealed/products/cushion_big.png";
import Ethafoam_small from "../../../../images/products/category/frommsealed/products/ethafoam_small.jpg";
import Ethafoam_big from "../../../../images/products/category/frommsealed/products/ethafoam_big.jpg";
import Instapak_small from "../../../../images/products/category/frommsealed/products/instapak_small.png";
import Instapak_big from "../../../../images/products/category/frommsealed/products/instapak_big.png";
import Bubble_small from "../../../../images/products/category/frommsealed/products/bubble_small.png";
import Bubble_big from "../../../../images/products/category/frommsealed/products/bubble_big.png";
import Shrinkmachine_small from "../../../../images/products/category/frommsealed/products/shrinkmachine_small.png";
import Shrinkmachine_big from "../../../../images/products/category/frommsealed/products/shrinkmachine_big.png";

// Starcke and 3M Products
import Starckerange_small from "../../../../images/products/category/starcke3m/products/starckerange_small.png";
import Starckerange_big from "../../../../images/products/category/starcke3m/products/starckerange_big.png";
import Sheets_small from "../../../../images/products/category/starcke3m/products/sheets_small.png";
import Sheets_big from "../../../../images/products/category/starcke3m/products/sheets_big.png";
import Surface_small from "../../../../images/products/category/starcke3m/products/surface_small.png";
import Surface_big from "../../../../images/products/category/starcke3m/products/surface_big.png";
import Disc_small from "../../../../images/products/category/starcke3m/products/disc_small.png";
import Disc_big from "../../../../images/products/category/starcke3m/products/disc_big.png";
import Belts_small from "../../../../images/products/category/starcke3m/products/belts_small.png";
import Belts_big from "../../../../images/products/category/starcke3m/products/belts_big.png";
import Orbital_small from "../../../../images/products/category/starcke3m/products/orbital_small.png";
import Orbital_big from "../../../../images/products/category/starcke3m/products/orbital_big.png";
import Vhb_small from "../../../../images/products/category/starcke3m/products/vhb_small.png";
import Vhb_big from "../../../../images/products/category/starcke3m/products/vhb_big.png";
import Primer_small from "../../../../images/products/category/starcke3m/products/primer_small.png";
import Primer_big from "../../../../images/products/category/starcke3m/products/primer_big.png";
import Instadhs_small from "../../../../images/products/category/starcke3m/products/instadhs_small.png";
import Instadhs_big from "../../../../images/products/category/starcke3m/products/instadhs_big.png";
import Masktape_small from "../../../../images/products/category/starcke3m/products/masktape_small.png";
import Masktape_big from "../../../../images/products/category/starcke3m/products/masktape_big.png";
import Caseseal_small from "../../../../images/products/category/starcke3m/products/caseseal_small.png";
import Caseseal_big from "../../../../images/products/category/starcke3m/products/caseseal_big.png";
import Tmadhs_small from "../../../../images/products/category/starcke3m/products/3madhs_small.png";
import Tmadhs_big from "../../../../images/products/category/starcke3m/products/3madhs_big.png";

// Stanley Black and Decker & Walter Products
import Hand_small from "../../../../images/products/category/sbdwalter/products/hand_small.png";
import Hand_big from "../../../../images/products/category/sbdwalter/products/hand_big.png";
import Power_small from "../../../../images/products/category/sbdwalter/products/power_small.png";
import Power_big from "../../../../images/products/category/sbdwalter/products/power_big.png";
import Cordless_small from "../../../../images/products/category/sbdwalter/products/cordless_small.png";
import Cordless_big from "../../../../images/products/category/sbdwalter/products/cordless_big.png";
import Garden_small from "../../../../images/products/category/sbdwalter/products/garden_small.png";
import Garden_big from "../../../../images/products/category/sbdwalter/products/garden_big.png";
import Pressure_small from "../../../../images/products/category/sbdwalter/products/pressure_small.png";
import Pressure_big from "../../../../images/products/category/sbdwalter/products/pressure_big.png";
import Blow_small from "../../../../images/products/category/sbdwalter/products/blow_small.png";
import Blow_big from "../../../../images/products/category/sbdwalter/products/blow_big.png";
import Inserts_small from "../../../../images/products/category/sbdwalter/products/inserts_small.png";
import Inserts_big from "../../../../images/products/category/sbdwalter/products/inserts_big.png";
import Turning_small from "../../../../images/products/category/sbdwalter/products/turning_small.png";
import Turning_big from "../../../../images/products/category/sbdwalter/products/turning_big.png";
import Hole_small from "../../../../images/products/category/sbdwalter/products/hole_small.png";
import Hole_big from "../../../../images/products/category/sbdwalter/products/hole_big.png";
import Thread_small from "../../../../images/products/category/sbdwalter/products/thread_small.png";
import Thread_big from "../../../../images/products/category/sbdwalter/products/thread_big.png";
import Milling_small from "../../../../images/products/category/sbdwalter/products/milling_small.png";
import Milling_big from "../../../../images/products/category/sbdwalter/products/milling_big.png";
import Hold_small from "../../../../images/products/category/sbdwalter/products/hold_small.png";
import Hold_big from "../../../../images/products/category/sbdwalter/products/hold_big.png";

// Southern Packaging and Shine Products
import Bathroom_big from "../../../../images/products/category/spshine/products/bathroom_big.png";
import Hard_big from "../../../../images/products/category/spshine/products/hard_big.png";
import Glass_big from "../../../../images/products/category/spshine/products/glass_big.png";
import Furnisher_big from "../../../../images/products/category/spshine/products/furnisher_big.png";
import Air_big from "../../../../images/products/category/spshine/products/air_big.png";
import Toilet_big from "../../../../images/products/category/spshine/products/toilet_big.png";
import Hardness_big from "../../../../images/products/category/spshine/products/hardness_big.png";
import Stainless_big from "../../../../images/products/category/spshine/products/stainless_big.png";
import Bathclean_big from "../../../../images/products/category/spshine/products/bathclean_big.png";
import Stationary_small from "../../../../images/products/category/spshine/products/stationary_small.png";
import Stationary_big from "../../../../images/products/category/spshine/products/stationary_big.png";
import Cleaning_small from "../../../../images/products/category/spshine/products/cleaning_small.png";
import Cleaning_big from "../../../../images/products/category/spshine/products/cleaning_big.png";

// End of Image Imports

export const productsData = [
  {
    id: 1,
    url: "consumables",
    category: "Packaging Consumables",
    img: Consumbales,
    products: [
      {
        id: "cp1",
        name: "Angleboards & Edge Protectors",
        imgSmall: Angle_small,
        imgLarge: Angle_big,
        desc: `Manufacturers of Angle boards in all sizes. Manufacturing Unit at Sriperumbudur.`,
      },
      {
        id: "cp2",
        name: "PP Strap",
        imgSmall: Pp_small,
        imgLarge: Pp_big,
        desc: `Manufacturers of plain, Colored and printed PP straps suitable for both automatic and semi-automatic machines. State of art manufacturing unit at Sriperumbudur.
        `,
      },
      {
        id: "cp3",
        name: "Plywood, Pinewood Pallets & Boxes",
        imgSmall: Ply_small,
        imgLarge: Ply_big,
        desc: `Manufacturers of IPSM15 compliant plywood and pinewood pallets, boxes & crates. We have our own heat treatment plant.`,
      },
      {
        id: "cp4",
        name: "Nails & Staples",
        imgSmall: Nail_small,
        imgLarge: Nail_big,
        desc: `Coil nails, Strip nails, Screw
        shank nails & Ring shank nails,
        Carton and wood staple pins in
        different sizes.        
        `,
      },
      {
        id: "cp5",
        name: "Customized Bags, LDPE, VCI & AL Foil",
        imgSmall: Bags_small,
        imgLarge: Bags_big,
        desc: `Coil nails, Strip nails, Screw
        shank nails & Ring shank nails,
        Carton and wood staple pins in
        different sizes.        
        `,
      },
      {
        id: "cp6",
        name: "Seals",
        imgSmall: Seal_small,
        imgLarge: Seal_big,
        desc: `Seals for steel, pet and PP Straps.`,
      },
      {
        id: "cp7",
        name: "Premium BOPP Tapes",
        imgSmall: Bopp_small,
        imgLarge: Bopp_big,
        desc: `We manufacture wide range of
        packing tapes. We also offer
        customized printed tapes with
        your name and logo.`,
      },
      {
        id: "cp8",
        name: "Pet Strap",
        imgSmall: Pet_small,
        imgLarge: Pet_big,
        desc: `We manufacture wide range of
        packing tapes. We also offer
        customized printed tapes with
        your name and logo.`,
      },
      {
        id: "cp9",
        name: "Stretch & Shrink Films",
        imgSmall: Shrink_small,
        imgLarge: Shrink_big,
        desc: `Machine grade, Hand grade and
        pre stretch films. Shrink film rolls
        and pouches in all sizes.`,
      },
      {
        id: "cp10",
        name: "Foam Rolls & Bubble Films",
        imgSmall: Foam_small,
        imgLarge: Foam_big,
        desc: `We offer wide range of foam
        rolls and bubble films in all kind
        of packing requirements.
        Assured quality, gauge, & length.
        `,
      },
      {
        id: "cp11",
        name: "Steel Strap",
        imgSmall: SteelStrap_small,
        imgLarge: SteelStrap_big,
        desc: `Painted and waxed steel straps
        in all sizes.
        `,
      },
      {
        id: "cp12",
        name: "Cord Strap & Buckles",
        imgSmall: Cord_small,
        imgLarge: Cord_big,
        desc: `We provide the wide range of
        Container Lashing Cord Straps
        with Buckles in all sizes.
        `,
      },
    ],
  },
  {
    id: 2,
    url: "tools",
    category: "Tools & Equipment",
    img: Tools,
    products: [
      {
        id: "tp1",
        name: "Pneumatic Nailer & Stapler",
        imgSmall: Nailer_small,
        imgLarge: Nailer_big,
        desc: `Nailers & Staplers increase
        productivity, reduce manpower
        & saves money.`,
      },
      {
        id: "tp2",
        name: "Semi & Fully Automatic Strapping Machines",
        imgSmall: Autostrapmachine_small,
        imgLarge: Autostrapmachine_big,
        desc: `Hi-speed strapping machines
        will increase your productivity.`,
      },
      {
        id: "tp3",
        name: "Economy Strapping Tools",
        imgSmall: Economy_small,
        imgLarge: Economy_big,
        desc: `We offer manual / Pneumatic /
        Battery Operated plastic &
        steel strapping tools.`,
      },
      {
        id: "tp4",
        name: "Sealing Machines",
        imgSmall: Sealing_small,
        imgLarge: Sealing_big,
        desc: `We offer all types of sealing
        machines like tong sealer,
        pedal sealer, band sealer, etc.`,
      },
      {
        id: "tp5",
        name: "Manual Strapping Tools",
        imgSmall: Manual_small,
        imgLarge: Manual_big,
        desc: `Complete range of steel and
        plastic strapping tools.
        `,
      },
      {
        id: "tp6",
        name: "Dispensers",
        imgSmall: Dispensers_small,
        imgLarge: Dispensers_big,
        desc: `Dispensers for steel, PET, PP,
        BOPP & Stretch Films.
        `,
      },
      {
        id: "tp7",
        name: "Semi & Automatic Shrink Wrapping Machines",
        imgSmall: ShrinkWrap_small,
        imgLarge: ShrinkWrap_big,
        desc: `We provide shrink wrapping
        machines in various sizes and
        specifications to cater our
        client’s specific demands.        
        `,
      },
      {
        id: "tp8",
        name: "Taping Machines",
        imgSmall: Taping_small,
        imgLarge: Taping_big,
        desc: `Tailor made taping machines
        according to your needs help
        increase your productivity.        
        `,
      },
      {
        id: "tp9",
        name: "Customized Packing Automation",
        imgSmall: Customized_small,
        imgLarge: Customized_big,
        desc: `Tailor made automated packing lines as
        per your needs.        
        `,
      },
    ],
  },
  {
    id: 3,
    url: "zavenirproducts",
    category: "Zavenir Daubert Products",
    img: Zavenir,
    products: [
      {
        id: "zp1",
        name: "CCPP",
        imgSmall: Ccpp_small,
        imgLarge: Ccpp_big,
        desc: `Unique proposition of cut, clean,
        protect & pack. Harmony in Chemistry.
        `,
        points: false,
      },
      {
        id: "zp2",
        name: "Hakuform - Neat Cutting Oils",
        imgSmall: Cutting_small,
        imgLarge: Cutting_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Hydrocracked Group II base oils." },
          { point: "Chlorine free EP additives." },
          { point: "Better Tool Life." },
          { point: "Reduced evaporation & carry over loses." },
        ],
      },
      {
        id: "zp3",
        name: "Hakuform - Water Soluble Cutting Oils",
        imgSmall: Water_small,
        imgLarge: Water_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Semi Synthetic." },
          { point: "Micro emulsion." },
          { point: "Operator friendly." },
          { point: "Clean machines." },
          { point: "Less Consumption more Savings." },
        ],
      },
      // {
      //   id: "zp4",
      //   name: "Hakupur - Advance Aqueous Cleaners",
      //   imgSmall: Aqueous_small,
      //   imgLarge: Aqueous_big,
      //   desc: "",
      //   points: true,
      //   pointsData: [
      //     { point: "Advanced Surfactant technology." },
      //     { point: "Better Millipore value." },
      //     { point: "Better intermediate rust protection." },
      //   ],
      // },
      {
        id: "zp5",
        name: "Nox-Rust (Rust Preventive Oils)",
        imgSmall: Noxrust_small,
        imgLarge: Noxrust_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Thin film technology." },
          { point: "Non-sticky films." },
          { point: "Reduced consumption." },
          { point: "Better rust protection." },
          { point: "Oils for exports and domestic." },
        ],
      },
      {
        id: "zp6",
        name: "Evapo-Rust (Rust Remover)",
        imgSmall: Evapo_small,
        imgLarge: Evapo_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "pH Neutral." },
          { point: "Water based." },
          { point: "Totally safe to use." },
          { point: "Will react only on rust." },
          { point: "Will not react on metal, plastic or rubber." },
        ],
      },
      {
        id: "zp7",
        name: "Daubert VCI Papers",
        imgSmall: Vci_small,
        imgLarge: Vci_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Proprietary VCI technology." },
          { point: "Virgin wood pulp." },
          { point: "Multi metal protection." },
        ],
      },
      {
        id: "zp8",
        name: "Daubert VCI Films",
        imgSmall: Film_small,
        imgLarge: Film_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Multi layered." },
          { point: "Proprietary VCI technology." },
          { point: "Better rust protection." },
          { point: "More strength & lower moisture transmission." },
        ],
      },
      {
        id: "zp9",
        name: "Daubrite NF - VCI Emmiter cum Dessicant",
        imgSmall: Emmiter_small,
        imgLarge: Emmiter_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "Absorbs moisture." },
          { point: "Emits VCI." },
          { point: "Better void space coverage." },
        ],
      },
      {
        id: "zp10",
        name: "ISOGOL Paint Coagulants",
        imgSmall: Paint_small,
        imgLarge: Paint_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "High density/low water content of paint sludge." },
          { point: "No corrosion in installations." },
          { point: "Lower waste generation, Less residues." },
        ],
      },
      // {
      //   id: "zp11",
      //   name: "NIKUTEX & HAKU  - Paint Purge & thinners",
      //   imgSmall: Ppt_small,
      //   imgLarge: Ppt_big,
      //   desc: "",
      //   points: true,
      //   pointsData: [
      //     { point: "New generation: VOC-reduced." },
      //     {
      //       point:
      //         "Adapted to reduce the cleaning cycles and increase the dissolution power.",
      //     },
      //     { point: "Worker health friendly, Ease of use." },
      //   ],
      // },
      {
        id: "zp12",
        name: "CONTROX Paint Strippers",
        imgSmall: Strippers_small,
        imgLarge: Strippers_big,
        desc: "",
        points: true,
        pointsData: [
          { point: "High stripping performance." },
          { point: "Long bath lives." },
          { point: "Human and environment friendly (Phenol Free)." },
        ],
      },
    ],
  },
  {
    id: 4,
    url: "frommsealedproducts",
    category: "Fromm & Sealed Air Products",
    img: FromSealed,
    products: [
      {
        id: "fp1",
        name: "Battery Plastic Strapping Tools",
        imgSmall: Batplastic_small,
        imgLarge: Batplastic_big,
        desc: `Strapping tools for plastic that
        are battery operated, compact,
        very light, with very good
        ergonomics, well balanced and
        easy to use.`,
      },
      {
        id: "fp2",
        name: "Pneumatic Plastic Strapping Tools",
        imgSmall: Pneumatic_small,
        imgLarge: Pneumatic_big,
        desc: `Pneumatically powered,
        reliable and maintenance-friendly 2-in-1, seal less tool.
        The perfect solution for production lines.`,
      },
      {
        id: "fp3",
        name: "Manual Seal & Seal-less Steel Strapping Tools",
        imgSmall: SealM_small,
        imgLarge: SealM_big,
        desc: `The manual sealer is extremely
        safe unique and durable of unsurpassed quality.
        The seal less joint reduces the strapping costs by approx. 10%. `,
      },
      {
        id: "fp4",
        name: "Pneumatic Seal & Seal-less Steel Strapping Tools",
        imgSmall: Pseal_small,
        imgLarge: Ppseal_big,
        desc: `The seal less joint reduces the
        strapping costs by 10%.
        High performance machines with an outstanding record of
        performance in the steel and non-ferrous industries.
        `,
      },
      {
        id: "fp5",
        name: "Stretch Wrapping Machines",
        imgSmall: Pstretch_small,
        imgLarge: Pstretch_big,
        desc: `FROMM stretch wrappers are
        extremely user-friendly and can be used in every situation. Extra
        emphasis was placed during the design stage on safety and reliability.`,
      },
      {
        id: "fp6",
        name: "Airpad - Product Protection and Void Filling with Air",
        imgSmall: Airpad_small,
        imgLarge: Airpad_big,
        desc: `Airpad is the multi-use interior
        packaging material that adequately services the need
        for product protection, shock absorption and void-filling applications.`,
      },
      {
        id: "fp7",
        name: "Cryovac Shrink Film",
        imgSmall: Cryovac_small,
        imgLarge: Cryovac_big,
        desc: `Shrink film provides enhanced
        seal strength, increased durability and retail-ready optics and clarity.
        `,
      },
      {
        id: "fp8",
        name: "Paper Cushioning System",
        imgSmall: Cushion_small,
        imgLarge: Cushion_big,
        desc: `The ProPad paper cushioning
        system can be used for
        cushioning, wrapping, and
        blocking and bracing
        applications.
        `,
      },
      {
        id: "fp9",
        name: "Ethafoam",
        imgSmall: Ethafoam_small,
        imgLarge: Ethafoam_big,
        desc: `The Ethafoam line is known for its
        outstanding dimensional stability
        and recovery characteristics,
        while providing unparalleled
        cushioning protection against
        repeated impacts.
        `,
      },
      {
        id: "fp10",
        name: "Instapak",
        imgSmall: Instapak_small,
        imgLarge: Instapak_big,
        desc: `Foam molding systems produce
        custom shaped cushions for
        products that require the
        consistent, precise fit of
        engineered protection.
        `,
      },
      {
        id: "fp11",
        name: "Bubble Wrap",
        imgSmall: Bubble_small,
        imgLarge: Bubble_big,
        desc: `BUBBLE WRAP brand air
        pillows made from recycled content. 
        Each pillow is manufactured from reusable,
        low-density polyethylene. 
        `,
      },
      {
        id: "fp12",
        name: "Shrink Wrapping Machines",
        imgSmall: Shrinkmachine_small,
        imgLarge: Shrinkmachine_big,
        desc: `SEALED AIR brand Shanklin shrink
        wrap L-sealers are capable of
        producing shrink wrapped packages
        without an operator, semi-automatic
        machines that require operator assistance, 
        and multi packing models to group multiple 
        products together.
        `,
      },
    ],
  },
  {
    id: 5,
    url: "starcke3mproducts",
    category: "Starcke & 3M Products",
    img: Starcke3m,
    products: [
      {
        id: "smp1",
        name: "Starcke Range of Products",
        imgSmall: Starckerange_small,
        imgLarge: Starckerange_big,
        desc: `Complete range of products for
        all applications.`,
      },
      {
        id: "smp2",
        name: "Sheets",
        imgSmall: Sheets_small,
        imgLarge: Sheets_big,
        desc: `Various grades with paper
        backing for painter’s
        applications. Durable paper for dust-free sanding operations.
        Waterproof MATADOR paper for perfect surface finishing.`,
      },
      {
        id: "smp3",
        name: "Surface Conditioning Discs and Belts",
        imgSmall: Surface_small,
        imgLarge: Surface_big,
        desc: `Majorly used on metals to
        create desired surface finish.`,
      },
      {
        id: "smp4",
        name: "Discs",
        imgSmall: Disc_small,
        imgLarge: Disc_big,
        desc: `Velcro discs with paper backing
        for painters' applications.
        Available in a variety of hole
        patterns. Velcro discs on
        durable paper for dust-free sanding 
        operations and perfect surfaces.
        `,
      },
      {
        id: "smp5",
        name: "Belts",
        imgSmall: Belts_small,
        imgLarge: Belts_big,
        desc: `Various types of belts available
        in aluminum oxide, zirconia,
        ceramic, silicon carbide based
        on customer requirements
        which is majorly used for mild
        & heavy stock removal.
        `,
      },
      {
        id: "smp6",
        name: "Orbital & Belt Sanders",
        imgSmall: Orbital_small,
        imgLarge: Orbital_big,
        desc: `Mainly used for surface
        preparation. Belt sanders mainly used 
        for deburring application.
        `,
      },
      {
        id: "smp7",
        name: "VHB Tapes",
        imgSmall: Vhb_small,
        imgLarge: Vhb_big,
        desc: `The ever
        -growing industrial sector,
        for over two decades have been
        using 3M VHB tapes for its
        endurance. They adhere and seal
        diverse substrates with high
        endurance standards.
        `,
      },
      {
        id: "smp8",
        name: "Primer",
        imgSmall: Primer_small,
        imgLarge: Primer_big,
        desc: `3M Tape Primer 94 can be used to
        improve the overall adhesion of
        tape, film and vinyl graphics to
        polyethylene, polypropylene, ABS,
        PET/PBT blends, concrete, wood,
        glass, metal and painted metal
        surfaces.        
        `,
      },
      {
        id: "smp9",
        name: "Instant Adhesives",
        imgSmall: Instadhs_small,
        imgLarge: Instadhs_big,
        desc: `Instant adhesives are designed for
        processes with high strength
        requirements for a small bond area,
        tight-fitting joints, and a need for
        rapid curing.  
        `,
      },
      {
        id: "smp10",
        name: "Masking Tapes",
        imgSmall: Masktape_small,
        imgLarge: Masktape_big,
        desc: `3M masking tapes are one of the
        earliest innovations of the company.
        The assurance provided by decades
        of testing in the automobile sector -
        masking tapes has become one of
        the brand ambassadors from the
        entire product range.
        `,
      },
      {
        id: "smp11",
        name: "3M Case Sealing Machines & Tapes",
        imgSmall: Caseseal_small,
        imgLarge: Caseseal_big,
        desc: `Box sealing tapes from 3M deliver
        convenience and dependability.
        They're build tough, to resist score
        line breaking and center seam
        splitting even when boxes are
        handled roughly.        
        `,
      },
      {
        id: "smp12",
        name: "3M Adhesives",
        imgSmall: Tmadhs_small,
        imgLarge: Tmadhs_big,
        desc: `3M Scotch-Weld Epoxy Adhesive features an extended 
        work life for adjustment and precise application,
        and reaches handling strength in 10
        hours. It is engineered for a 1:1 mix
        ratio and exhibits good environmental aging properties.       
        `,
      },
    ],
  },
  {
    id: 6,
    url: "sbdwalter",
    category: "Stanley Black and Decker & Walter Products",
    img: Sbdwalter,
    products: [
      {
        id: "sbdp1",
        name: "Stanley Hand Tools",
        imgSmall: Hand_small,
        imgLarge: Hand_big,
        desc: `STANLEY manufactures hand
        tools for professionals and for
        those who demand professional equipment to
        build, make, repair, and sometimes demolish.`,
      },
      {
        id: "sbdp2",
        name: "DeWalt Power Tools",
        imgSmall: Power_small,
        imgLarge: Power_big,
        desc: `DEWALT Power Tools that are
        made for your jobs.
        `,
      },
      {
        id: "sbdp3",
        name: "DeWalt Cordless Tools",
        imgSmall: Cordless_small,
        imgLarge: Cordless_big,
        desc: `The DEWALT battery-operated equipment 
        maximizes efficiency by eliminating trips
        to the pump, fuel mixing,
        constant pull starts, minimizes
        maintenance and provides low
        noise levels.
        `,
      },
      {
        id: "sbdp4",
        name: "Black and Decker Gardening Tools",
        imgSmall: Garden_small,
        imgLarge: Garden_big,
        desc: `Black & decker’s corded and
        cordless tools help with
        maintaining gardens, driveways
        and sidewalks.
        `,
      },
      {
        id: "sbdp5",
        name: "Black and Decker Pressure Washers",
        imgSmall: Pressure_small,
        imgLarge: Pressure_big,
        desc: `Black & decker’s pressure
        washers are ideal for cleaning
        bikes, cars, garden furniture.
        `,
      },
      {
        id: "sbdp6",
        name: "Stanley Blower & Heat Guns",
        imgSmall: Blow_small,
        imgLarge: Blow_big,
        desc: `Light & compact design for
        ease of handling. Lock on
        switch for ease of operation
        optimized fan structure for
        better air flow.
        `,
      },
      {
        id: "sbdp7",
        name: "Walter Inserts",
        imgSmall: Inserts_small,
        imgLarge: Inserts_big,
        desc: `Walter brand comprises
        carbide indexable inserts and
        PCDs for milling, turning,
        grooving & hole making.
        `,
      },
      {
        id: "sbdp8",
        name: "Walter Turning Tool",
        imgSmall: Turning_small,
        imgLarge: Turning_big,
        desc: `Walter offers a complete range
        of tools for turning, grooving
        and thread machining. Further
        materials made of CBN, PCD
        and ceramics make Walter a
        comprehensive provider in
        machine turning.
        `,
      },
      {
        id: "sbdp9",
        name: "Hole Making Tool",
        imgSmall: Hole_small,
        imgLarge: Hole_big,
        desc: `The Walter and Walter Titex
        brands offer a full range of
        solutions for efficient drilling.
        Standard range of drills with
        diameters from 0.05 mm to
        100 mm.
        `,
      },
      {
        id: "sbdp10",
        name: "Threading Tools",
        imgSmall: Thread_small,
        imgLarge: Thread_big,
        desc: `Walter provides standard HSS -
        E(-PM) and solid carbide taps
        and thread formers, as well as
        thread mills and indexable inserts.
        `,
      },
      {
        id: "sbdp11",
        name: "Milling Tools",
        imgSmall: Milling_small,
        imgLarge: Milling_big,
        desc: `Solid carbide mini mill with a
        diameter of 0.1 mm to the face
        mill with 315 mm diameter carbide indexable inserts.
        Various tool material grades, as coated carbide, 
        PCD, CBN or HSS.
        `,
      },
      {
        id: "sbdp12",
        name: "Walter Tool Holder",
        imgSmall: Hold_small,
        imgLarge: Hold_big,
        desc: `In Walter we can find a
        selection of our clamping units
        and adaptor systems, ranging
        from modular systems to our
        Monoblock adaptors.
        `,
      },
    ],
  },
  {
    id: 7,
    url: "spshineproducts",
    category: "Southern Packaging & Shine Products",
    img: Spshine,
    products: [
      {
        id: "spsp1",
        name: "Shine Premium S1 - Bathroom Sanitizer",
        imgSmall: Bathroom_big,
        imgLarge: Bathroom_big,
        desc: `It is used to clean the floors,
        kitchens and bathrooms. It
        sanitizes against dirt and
        germs, all the while giving a
        pleasant fragrance.`,
        height: true,
      },
      {
        id: "spsp2",
        name: "Shine Premium S2 - Hygienic Hard Surface Cleaner",
        imgSmall: Hard_big,
        imgLarge: Hard_big,
        desc: `This product can clean all types
        of hard surfaces and gives a
        good appearance on all applied
        areas.`,
        height: true,
      },
      {
        id: "spsp3",
        name: "Shine Premium S3 - Glass Cleaner",
        imgSmall: Glass_big,
        imgLarge: Glass_big,
        desc: `This product is suitable for use
        on all glass surfaces such as
        glass partitions, mirrors,
        commercial glass windows, etc.
        `,
        height: true,
      },
      {
        id: "spsp4",
        name: "Shine Premium S4 - Furnisher Polish",
        imgSmall: Furnisher_big,
        imgLarge: Furnisher_big,
        desc: `This product is extremely
        efficient to polish and shine all
        types of wood surfaces.        
        `,
        height: true,
      },
      {
        id: "spsp5",
        name: "Shine Premium S5 - Air Freshener",
        imgSmall: Air_big,
        imgLarge: Air_big,
        desc: `This product is a non-alcohol,
        non-aerosol based room
        fragrance that lingers in the air
        for as much as 6 hours.        
        `,
        height: true,
      },
      {
        id: "spsp6",
        name: "Shine Premium S6 - Toilet Bowl Cleaner",
        imgSmall: Toilet_big,
        imgLarge: Toilet_big,
        desc: `Designed for sanitation of toilet
        bowls and urinals.        
        `,
        height: true,
      },
      {
        id: "spsp7",
        name: "Shine Premium S7 - Hardness Surface Cleaner",
        imgSmall: Hardness_big,
        imgLarge: Hardness_big,
        desc: `Effective in cleaning and
        removal strains from all kind of
        floors.        
        `,
        height: true,
      },
      {
        id: "spsp8",
        name: "Shine Premium SD7 - Stainless Steel Polish",
        imgSmall: Stainless_big,
        imgLarge: Stainless_big,
        desc: `Designed to remove deposits
        on stainless steel surfaces. It
        gives a clean and shiny
        appearance.        
        `,
        height: true,
      },
      {
        id: "spsp9",
        name: "Shine Premium S9 - Bathroom Cleaner (Concentrated for Hard Water)",
        imgSmall: Bathclean_big,
        imgLarge: Bathclean_big,
        desc: `Specially designed to remove
        scale deposits from bathroom,
        kitchen and various hard
        surfaces.        
        `,
        height: true,
      },
      {
        id: "spsp10",
        name: "Stationary Items",
        imgSmall: Stationary_small,
        imgLarge: Stationary_big,
        desc: `All Common and Office Stationary Items are available.        
        `,
        height: true,
        imgHeight: true,
      },
      {
        id: "spsp11",
        name: "Cleaning Appliances",
        imgSmall: Cleaning_small,
        imgLarge: Cleaning_big,
        desc: `All Cleaning Appliances are available.`,
        height: true,
        imgHeight: true,
      },
    ],
  },
];
