import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Services.css";

// Image Imports

import Service1 from "../../../images/service_1.svg";
import Service2 from "../../../images/service_2.svg";
import Service3 from "../../../images/service_3.svg";

// End of Image Imports

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Our Services - Southern Packaging`;
  }, []);
  return (
    <section className="services-section-wrap">
      <div className="services-section section-padding">
        <div className="container">
          <h3 className="section-header">
            Our <span>Services</span>
          </h3>
          <div className="srv-text">
            <p>
              We at Southern Packaging are envisaged to be a one stop packaging
              store, Providing customers with quality products and service,
              Always striving to reduce the customers cost by providing
              innovative solutions and products.
            </p>
          </div>
          <div className="services-item-wrap">
            <Row>
              <Col sm="12" lg="3" md="3">
                <Link to="/services/maintenance">
                  <div className="service-item">
                    <div className="srv-img">
                      <img src={Service1} alt="Service 1" />
                    </div>
                    <h5>Maintenance and Repairs of Packaging Machines</h5>
                    <button className="btn btn-dark">View All</button>
                  </div>
                </Link>
              </Col>
              <Col sm="12" lg="3" md="3">
                <Link to="/services/packaging-contracts">
                  <div className="service-item">
                    <div className="srv-img">
                      <img src={Service2} alt="Service 2" />
                    </div>
                    <h5>Complete Packaging Contracts</h5>
                    <button className="btn btn-dark">View All</button>
                  </div>
                </Link>
              </Col>
              <Col sm="12" lg="3" md="3">
                <Link to="/services/packaging-design-development">
                  <div className="service-item">
                    <div className="srv-img">
                      <img src={Service3} alt="Service 3" />
                    </div>
                    <h5>Packaging Design and Development</h5>
                    <button className="btn btn-dark">View All</button>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
