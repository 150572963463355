import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Manufacturing.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import Strap from "../../../images/logo/company/manufacturing/strap.png";
import Ss_1 from "../../../images/manufacturing/safestrapping/ss_1.jpg";
import Ss_2 from "../../../images/manufacturing/safestrapping/ss_2.jpg";
import Ss_3 from "../../../images/manufacturing/safestrapping/ss_3.jpg";

// End of Image Imports

function SafeStrapping() {
  const safeOptions = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Safe Strapping | Manufacturing - Southern Packaging`;
  }, []);
  return (
    <section className="manufacturing-common-wrap">
      <div className="manufacturing-section section-padding">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between header-wrap">
            <h3 className="section-header">
              Safe<span> Strapping</span>
            </h3>
            <img src={Strap} width={220} alt="Safe Strapping" />
          </div>
          <div className="manufacturing-slider-wrap">
            <OwlCarousel className="owl-theme" {...safeOptions}>
              <div className="item">
                <img src={Ss_1} alt="Slider" />
              </div>
              <div className="item">
                <img src={Ss_2} alt="Slider" />
              </div>
              <div className="item">
                <img src={Ss_3} alt="Slider" />
              </div>
            </OwlCarousel>
          </div>
          <h5>About Us</h5>
          <div className="srv-text">
            <p>
              Our company - safe strapping is envisaged to be the producer of
              quality PP Strapping rolls suitable for automatic strapping
              machines. We are a group of packaging experts with over 50 years
              of collective experience in the packaging field. We have set up
              our manufacturing unit at Mannur village near Arrakkonam road.
            </p>
            <p>
              We have procured state of the art fully automatic strap
              manufacturing machine from jumbo steel Taiwan. Our machine is
              presently the best machine in south india. We have invested about
              rupees two crore on the project.
            </p>
            <p>
              Our PP strap will be free from defects and will run smoothly in
              your machines reducing your down time and increasing productivity.
              We also have full fledged lab for ensuring that all parameters of
              your product are as per the agreed specifications. We provide test
              certifications with every dispatch. Our production head Mr.Gandhi
              is a specialist in PP Manufacturing field with over 16 years of
              experience. He was working with ITW Signode & Sri Krishna
              Strapping before joining us as partner. We are confident of giving
              you a product which meets all your requirments and reduces your
              cost.
            </p>
          </div>
          <div className="divider"></div>
          <h5>Usability</h5>
          <div className="safe-wrap">
            <Row>
              <Col sm="12" lg="4" md="4">
                <div className="safe-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="safe-icon">
                      <h6>1</h6>
                    </div>
                  </div>
                  <p>
                    State of the art manufacturing unit gives defect free strap
                    with no cambers and curls. This will result in reduced down
                    time and increased productivity.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="safe-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="safe-icon">
                      <h6>2</h6>
                    </div>
                  </div>
                  <p>Always as per specifications and assured length.</p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="safe-item">
                  <div className="d-flex align-items-center gap-3">
                    <div className="safe-icon">
                      <h6>3</h6>
                    </div>
                  </div>
                  <p>High strength straps and every batch gets tested in our laboratory for strength.</p>
                </div>
              </Col>
            </Row>
          </div>
          <Link to="/contact">
            <button className="btn btn-dark mt-4">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default SafeStrapping;
