import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Services.css";

import Breadcrumbs from "../Common/Breadcrumbs";

// Image Imports
import Main_1 from "../../../images/services/maintenance/main_1.jpg";
import Main_2 from "../../../images/services/maintenance/main_2.jpg";
import Main_3 from "../../../images/services/maintenance/main_3.jpg";
import Main_4 from "../../../images/services/maintenance/main_4.jpg";
import Main_5 from "../../../images/services/maintenance/main_5.jpg";
import Main_6 from "../../../images/services/maintenance/main_6.jpg";
import Main_7 from "../../../images/services/maintenance/main_7.jpg";
import Main_8 from "../../../images/services/maintenance/main_8.jpg";
import Main_9 from "../../../images/services/maintenance/main_9.jpg";
import Main_10 from "../../../images/services/maintenance/main_10.jpg";
import Main_11 from "../../../images/services/maintenance/main_11.jpg";
import Main_12 from "../../../images/services/maintenance/main_12.jpg";
import Main_13 from "../../../images/services/maintenance/main_13.jpg";
import Main_14 from "../../../images/services/maintenance/main_14.jpg";
import Main_15 from "../../../images/services/maintenance/main_15.jpg";
import Main_16 from "../../../images/services/maintenance/main_16.jpg";

// End of Image Imports

const maintainSrc = [
  { img: Main_1 },
  { img: Main_2 },
  { img: Main_3 },
  { img: Main_4 },
  { img: Main_5 },
  { img: Main_6 },
  { img: Main_7 },
  { img: Main_8 },
  { img: Main_9 },
  { img: Main_10 },
  { img: Main_11 },
  { img: Main_12 },
  { img: Main_13 },
  { img: Main_14 },
  { img: Main_15 },
  { img: Main_16 },
];

function Maintenance() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Maintenance and Repairs of Packaging Machines - Southern Packaging`;
  }, []);
  return (
    <section className="services-common-wrap">
      <div className="services-common-section section-padding">
        <div className="container">
          <Link to="/services">
            <Breadcrumbs
              backText="Our Services"
              mainText="Maintenance and Repairs of"
              yellowText="Packaging Machines"
            />
          </Link>
          <div className="srv-text">
            <p>
              Annual Maintaince Contract for all Packing machine our trained and
              experienced service team, undertakes AMC'S for all makes of
              strapping, taping and Stretch wrapping machines, we also supply
              spare for all your packing machines.
            </p>
          </div>
          <div className="services-common-content-box">
            <Row>
              {maintainSrc.map((img) => (
                <Col sm="12" lg="3" md="6">
                  <div className="main-box">
                    <div className="main-img">
                      <img src={img.img} alt="Maintenance" />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Maintenance;
