import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Services.css";

import Breadcrumbs from "../Common/Breadcrumbs";

function PackagingDD() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Packaging Design and Development - Southern Packaging`;
  }, []);

  return (
    <section className="services-common-wrap">
      <div className="services-common-section section-padding">
        <div className="container">
          <Link to="/services">
            <Breadcrumbs
              backText="Our Services"
              mainText="Packaging"
              yellowText="Design and Development"
            />
          </Link>
          <div className="srv-text">
            <p>
              We can help you to design optimum packing for you product which
              will be as per Internal Standard.
            </p>
          </div>
          <div className="services-common-content-box">
            <Row>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/iipyzAvLJ68"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/iFZJg-guZX8"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/TJ68H56TxHc"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/GmSw2x4LJGg"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/I-Pa2cTspVU"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              <Col sm="12" lg="4" md="4">
                <div className="main-box">
                  <iframe
                    width="100%"
                    height="220"
                    src="https://www.youtube.com/embed/Udl05MKdvjA"
                    title="Pallet India"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PackagingDD;
