import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import { Row, Col } from "reactstrap";
import toast from "react-hot-toast";
import moment from "moment";
import "./Contact.css";

// Image Imports

import ContactImg from "../../../images/contact.svg";
import Call from "../../../images/icons/contact/call.svg";
import Email from "../../../images/icons/contact/email.svg";
import Location from "../../../images/icons/contact/location.svg";
import User from "../../../images/icons/form/user.svg";
import Phone from "../../../images/icons/form/phone.svg";
import Mail from "../../../images/icons/form/mail.svg";
import Globe from "../../../images/icons/form/globe.svg";
import LocationIcon from "../../../images/icons/form/location.svg";
import Product from "../../../images/icons/form/product.svg";
import Write from "../../../images/icons/form/write.svg";

// End of Image Imports

const initialValues = {
  full_name: "",
  phone_number: "",
  email: "",
  state: "",
  city: "",
  product: "",
  requirements: "",
};

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Contact Us - Southern Packaging`;
  }, []);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);

  const { full_name, phone_number, email, state, city, product, requirements } =
    values;

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    setValues({ ...values, [name]: value });
  };

  const onChangeNumber = (e, type) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      // Allow only numbers in textbox
      var num = String(e.target.value);
      setValues({
        ...values,
        [e.target.name]: num,
      });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const form = useRef();

  const submitHandler = (e) => {
    e.preventDefault();
    if (full_name === "") {
      return toast.error("Please let us know your name");
    }
    if (phone_number === "") {
      return toast.error("Please give us your contact number");
    }
    if (email === "") {
      return toast.error("Please give us your email", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    if (state === "") {
      return toast.error("Please mention your state");
    }
    if (city === "") {
      return toast.error("Please mention your city");
    }
    if (product === "") {
      return toast.error("Please mention the product you are looking for");
    }
    if (requirements === "") {
      return toast.error("Please tell us about your requirements");
    }
    setLoading(true);
    const data = new FormData();
    data.append("Name", full_name);
    data.append("Contact", phone_number);
    data.append("Email", email);
    data.append("State", state);
    data.append("City", city);
    data.append("Product", product);
    data.append("Requirements", requirements);
    data.append("Timestamp", moment().format("DD-MM-YYYY hh:mm a"));

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzNcFCFmjD3Wfq49Clyx9bQCi_YT8PVBDD2RtYtxxmdwyKaGMH6xUc0UopSMUSI4KfGBw/exec";
    fetch(scriptURL, { method: "POST", body: data })
      .then(() => {
        setValues(initialValues);
        setLoading(false);
        toast.success(
          "Message sent successfully! We will get back to you shortly!"
        );
      })
      .catch(() => toast.error("Sorry, try again!"));

    emailjs
      .sendForm(
        "service_y8nxkvb",
        "template_9vax98d",
        form.current,
        "prp3ytIrZ-NwfStP7"
      )
      .then(
        () => {},
        (error) => {
          toast.error("Sorry, try again!", error);
        }
      );
  };

  return (
    <section className="contact-us-wrap">
      <div className="contact-us-section section-padding">
        <div className="container">
          <h3 className="section-header">
            Make an <span>Enquiry</span>
          </h3>
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="contact-left-wrap">
                <h1 data-contact-subhead>
                  We'd Love to <span>hear from you!</span>
                </h1>
                <div className="contact-details-wrap">
                  <div className="cd-item">
                    <div className="cd-img">
                      <img src={Location} alt="Location" />
                    </div>
                    <div>
                      <h6>Reach Us</h6>
                      <p>
                        Plot No. IV, V & VI, Raja's Garden Phase - II, <br />{" "}
                        Noombal, Chennai - 600 077.
                      </p>
                    </div>
                  </div>
                  <div className="cd-item">
                    <div className="cd-img">
                      <img src={Call} alt="Call" />
                    </div>
                    <div>
                      <h6>Call Us</h6>
                      <p>
                        +91 98848 95147 <br />
                        (+91) - 89399 92997 / 94444 46611 / 94449 03131 / 94449
                        03166
                      </p>
                    </div>
                  </div>
                  <div className="cd-item">
                    <div className="cd-img">
                      <img src={Email} alt="Email" />
                    </div>
                    <div>
                      <h6>Mail Us</h6>
                      <p>ccare@southernpack.com</p>
                    </div>
                  </div>
                </div>
                <img
                  src={ContactImg}
                  className="contact-us-img"
                  alt="Contact Us"
                />
              </div>
            </Col>
            <Col
              sm="12"
              lg="6"
              md="6"
              className="d-flex justify-content-center"
            >
              <div className="contact-right-wrap">
                <form
                  ref={form}
                  method="POST"
                  className="contact-form"
                  onSubmit={submitHandler}
                >
                  <div className="form-box">
                    <input
                      type="text"
                      id="full_name"
                      value={full_name}
                      name="full_name"
                      onChange={handleChange}
                    />
                    <label htmlFor="full_name">Full Name</label>
                    <img src={User} alt="User" />
                  </div>
                  <div className="form-box">
                    <input
                      type="tel"
                      id="mobile_number"
                      value={phone_number}
                      name="phone_number"
                      onChange={onChangeNumber}
                      maxLength="10"
                    />
                    <label htmlFor="mobile_number">Mobile Number (+91)</label>
                    <img src={Phone} alt="Phone" />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      id="email_address"
                      value={email}
                      name="email"
                      onChange={handleChange}
                    />
                    <label htmlFor="email_address">Email Address</label>
                    <img src={Mail} alt="Mail" />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      id="state"
                      value={state}
                      name="state"
                      onChange={handleChange}
                    />
                    <label htmlFor="state">State</label>
                    <img src={Globe} alt="Globe" />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      id="city"
                      value={city}
                      name="city"
                      onChange={handleChange}
                    />
                    <label htmlFor="city">City</label>
                    <img src={LocationIcon} alt="Location" />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      id="product"
                      value={product}
                      name="product"
                      onChange={handleChange}
                    />
                    <label htmlFor="product">Enter Product</label>
                    <img src={Product} alt="Product" />
                  </div>
                  <div className="form-box">
                    <textarea
                      name="requirements"
                      id="requirements"
                      cols="30"
                      rows="6"
                      placeholder="Requirements"
                      value={requirements}
                      onChange={handleChange}
                    ></textarea>
                    <img src={Write} className="custom-img-post" alt="Write" />
                  </div>
                </form>
                <div className="d-block text-center mt-4">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    name="submit"
                    onClick={submitHandler}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contact-map-wrap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.7604007702307!2d80.13424951534358!3d13.050917416662779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261a3cf6749d1%3A0x3e5d2e88d3dee6dd!2sSouthern%20Packaging!5e0!3m2!1sen!2sin!4v1662576504369!5m2!1sen!2sin"
            width={"100%"}
            height="490"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Southern Packaging"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Contact;
