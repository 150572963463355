import React from "react";

// Image Imports

import contact from "../../../images/floating-menu/contact.svg";
import mail from "../../../images/floating-menu/mail.svg";
import facebook from "../../../images/floating-menu/facebook.svg";
import whatsapp from "../../../images/floating-menu/whatsapp.svg";

// End of Image Imports

function FloatingMenu() {
  return (
    <section className="floating-menu">
      <a href="tel:+919884895147" rel="noopener noreferrer">
        <div className="fl-items red">
          <h6 className="fl-exp">Contact Us</h6>
          <img
            src={contact}
            className="lazy"
            data-mdb-lazy-src
            alt="Contact Us"
          />
        </div>
      </a>
      <a href="mailto:ccare@southernpack.com" rel="noopener noreferrer">
        <div className="fl-items black">
          <h6 className="fl-exp">Email Us</h6>
          <img src={mail} className="lazy" data-mdb-lazy-src alt="Email Us" />
        </div>
      </a>
      <a href="https://www.facebook.com/SouthernPackaging" rel="noopener noreferrer" target="_blank">
        <div className="fl-items fb">
          <h6 className="fl-exp">Facebook</h6>
          <img
            src={facebook}
            className="lazy"
            data-mdb-lazy-src
            alt="Facebook"
            style={{ marginLeft: "2px" }}
          />
        </div>
      </a>
      <a
        href="https://wa.me/+919884895147"
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="fl-items wa">
          <h6 className="fl-exp">WhatsApp</h6>
          <img
            src={whatsapp}
            className="lazy"
            data-mdb-lazy-src
            alt="Whatsapp"
          />
        </div>
      </a>
    </section>
  );
}

export default FloatingMenu;
