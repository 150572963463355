import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Services.css";

import Breadcrumbs from "../Common/Breadcrumbs";

// Image Imports
import Pc_1 from "../../../images/services/packing-contracts/pc_1.jpg";
import Pc_2 from "../../../images/services/packing-contracts/pc_2.jpg";
import Pc_3 from "../../../images/services/packing-contracts/pc_3.jpg";
import Pc_4 from "../../../images/services/packing-contracts/pc_4.jpg";

// End of Image Imports

const contractsSrc = [
  { title: "Application of Rust Preventive Oil", img: Pc_1 },
  { title: "VCI Packing", img: Pc_2 },
  { title: "Palletising", img: Pc_3 },
  { title: "Container Lashing", img: Pc_4 },
];

function PackagingContacts() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Complete Packaging Contracts - Southern Packaging`;
  }, []);
  return (
    <section className="services-common-wrap">
      <div className="services-common-section section-padding">
        <div className="container">
          <Link to="/services">
            <Breadcrumbs
              backText="Our Services"
              mainText="Complete"
              yellowText="Packaging Contacts"
            />
          </Link>
          <div className="srv-text">
            <p>
              We undertake complete packing of your products. Our trained work
              force will do rust preventive treatment, VCI packing, cartoning,
              palletizing and container loading with lashing.
            </p>
            <p>
              We will use our packaging machine and labour and we will charge
              you on per component basis.
            </p>
          </div>
          <div className="services-common-content-box">
            <Row>
              {contractsSrc.map((pc) => (
                <Col sm="12" lg="3" md="3">
                  <div className="main-box pc">
                    <div className="main-img">
                      <img
                        className="spc-img"
                        src={pc.img}
                        alt="Packaging Contracts"
                      />
                    </div>
                    <h6 className="img-title">{pc.title}</h6>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PackagingContacts;
