import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Manufacturing.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import Sp from "../../../images/logo/company/manufacturing/southern.png";
import Sp_1 from "../../../images/manufacturing/southernpackaging/sp_1.jpg";
import Sp_2 from "../../../images/manufacturing/southernpackaging/sp_2.jpg";
import TwoBag_1 from "../../../images/manufacturing/southernpackaging/2d_1.jpg";
import TwoBag_2 from "../../../images/manufacturing/southernpackaging/2d_2.jpg";
import TwoBag_3 from "../../../images/manufacturing/southernpackaging/2d_3.jpg";
import TwoBag_4 from "../../../images/manufacturing/southernpackaging/2d_4.jpg";
import TwoBag_5 from "../../../images/manufacturing/southernpackaging/2d_5.jpg";
import Vci_1 from "../../../images/manufacturing/southernpackaging/vci_01.jpg";
import Vci_2 from "../../../images/manufacturing/southernpackaging/vci_02.jpg";
import Ld_1 from "../../../images/manufacturing/southernpackaging/ld_01.jpg";
import Ld_2 from "../../../images/manufacturing/southernpackaging/ld_02.jpg";
import Al_1 from "../../../images/manufacturing/southernpackaging/al_01.jpg";
import Al_2 from "../../../images/manufacturing/southernpackaging/al_02.jpg";
import Cut_1 from "../../../images/manufacturing/southernpackaging/cut_01.jpg";
import Cut_2 from "../../../images/manufacturing/southernpackaging/cut_02.jpg";
import Cut_3 from "../../../images/manufacturing/southernpackaging/cut_03.jpg";
import Cut_4 from "../../../images/manufacturing/southernpackaging/cut_04.jpg";
import Cut_5 from "../../../images/manufacturing/southernpackaging/cut_05.jpg";
import Cut_6 from "../../../images/manufacturing/southernpackaging/cut_06.jpg";
import Cut_7 from "../../../images/manufacturing/southernpackaging/cut_07.jpg";
import Cor_1 from "../../../images/manufacturing/southernpackaging/cor_01.jpg";

// End of Image Imports

function BagManufacturingUnit() {
  const cutSheets = [
    { title: "VCI Film Sheets", img: Cut_1 },
    { title: "VCI Paper Sheets", img: Cut_2 },
    { title: "Aluminum Foil Sheets", img: Cut_3 },
    { title: "Air Bubble Sheets", img: Cut_4 },
    { title: "Foam Sheets", img: Cut_5 },
    { title: "Corrugated Sheets", img: Cut_6 },
    { title: "LDPE Sheets", img: Cut_7 },
  ];

  const TwoDBags = [
    { title: "VCI Film Bags", img: TwoBag_1 },
    { title: "LDPE Bags", img: TwoBag_2 },
    { title: "Aluminium Foil Bags", img: TwoBag_3 },
    { title: "Air Bubble Bags", img: TwoBag_4 },
    { title: "Foam Bags - White", img: TwoBag_5 },
  ];

  const ThreeDBags = [
    {
      title: "VCI Film Bags",
      img: {
        withLid: Vci_1,
        withoutLid: Vci_2,
      },
    },
    {
      title: "LDPE Bags",
      img: {
        withLid: Ld_1,
        withoutLid: Ld_2,
      },
    },
    {
      title: "Aluminium Foil Bags",
      img: {
        withLid: Al_1,
        withoutLid: Al_2,
      },
    },
  ];

  const palletOptions = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Pallet India | Manufacturing - Southern Packaging`;
  }, []);
  return (
    <section className="manufacturing-common-wrap">
      <div className="manufacturing-section section-padding">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between header-wrap">
            <h3 className="section-header">
              Southern Packaging<span> Bag Manufacturing Unit</span>
            </h3>
            <img src={Sp} width={220} alt="Southern Packaging" />
          </div>
          <div className="manufacturing-slider-wrap">
            <OwlCarousel className="owl-theme" {...palletOptions}>
              <div className="item">
                <img src={Sp_1} alt="Slider" />
              </div>
              <div className="item">
                <img src={Sp_2} alt="Slider" />
              </div>
            </OwlCarousel>
          </div>
          <div className="srv-text">
            <p>
              In our bag making unit we make customized 2D and 3D bags tailor
              made as per your requirements. We give minimum numbers also and
              will give immediate deliveries.
            </p>
          </div>
          <div className="divider"></div>
          <h5>Our Products</h5>
          <p>
            We specialize in manufacturing the following listed set of material.
          </p>
          <h5>2D Bags</h5>
          <div className="services-common-content-box">
            <Row>
              {TwoDBags.map((pc) => (
                <Col sm="12" lg="3" md="3">
                  <div className="main-box pc">
                    <div className="main-img">
                      <img className="spc-img" src={pc.img} alt={pc.title} />
                    </div>
                    <h6 className="img-title">{pc.title}</h6>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="divider"></div>
          <h5>3D Bags</h5>
          <div className="services-common-content-box">
            {ThreeDBags.map((bags) => (
              <Row>
                <Col sm="12" lg="3" md="3">
                  <h6 className="cat-title">{bags.title}</h6>
                </Col>
                <Col sm="12" lg="3" md="3">
                  <div className="main-box">
                    <div className="main-img">
                      <img
                        className="spc-img"
                        src={bags.img.withLid}
                        alt={bags.title}
                      />
                    </div>
                    <h6 className="img-title">With Lid</h6>
                  </div>
                </Col>
                <Col sm="12" lg="3" md="3">
                  <div className="main-box">
                    <div className="main-img">
                      <img
                        className="spc-img"
                        src={bags.img.withoutLid}
                        alt={bags.title}
                      />
                    </div>
                    <h6 className="img-title">Without Lid</h6>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
          <div className="divider"></div>
          <h5>Cut Sheets</h5>
          <div className="services-common-content-box">
            <Row>
              {cutSheets.map((cs) => (
                <Col sm="12" lg="3" md="3">
                  <div className="main-box pc">
                    <div className="main-img">
                      <img className="spc-img" src={cs.img} alt={cs.title} />
                    </div>
                    <h6 className="img-title">{cs.title}</h6>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Corrugated Board Partitions</h5>
          <div className="man-content-box">
            <Row>
              <Col sm="12" lg="3" md="3">
                <img src={Cor_1} alt="Corrugated Board Partitions" />
              </Col>
            </Row>
          </div>
          <Link to="/contact">
            <button className="btn btn-dark mt-5">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default BagManufacturingUnit;
