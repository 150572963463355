import React from "react";

import BackIcon from "../../../images/icons/backIcon.svg";

function Breadcrumbs(props) {
  return (
    <div className="section-header section-breadcrumbs">
      <div className="hd-left">
        <img src={BackIcon} alt="Back" />
        <span data-breadcrumbs>{props.backText} /</span>
      </div>
      <div className="hd-right">
        <h3>
          {props.mainText} <span>{props.yellowText}</span>
        </h3>
      </div>
    </div>
  );
}

export default Breadcrumbs;
