import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import "./Products.css";
import { productsData } from "./data/data";

import Breadcrumbs from "../Common/Breadcrumbs";

// Image Imports

import closeIcon from "../../../images/icons/closeIcon.svg";

// End of Image Imports

function Products() {
  const { id } = useParams();
  const products = productsData.find((item) => item.url === id);

  // For Styling the Last Word of the Header
  const catName = products.category;
  const catNameSplit = products.category.split(" ");
  const lastWord = catNameSplit[catNameSplit.length - 1];
  const removeLastWord = catName.lastIndexOf(" ");
  const printfirstWord = catName.substring(0, removeLastWord);

  const [modal, setModal] = useState(false);
  const [value, setValue] = useState({
    name: "",
    imgLarge: "",
    desc: "",
  });

  const toggle = (product) => {
    setModal(!modal);
    setValue(product);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Products - Southern Packaging`;
  }, []);

  return (
    <>
      <section className="category-section-wrap">
        <div className="category-section section-padding">
          <div className="container">
            <Link to="/products">
              <Breadcrumbs
                backText="Our Products"
                mainText={printfirstWord}
                yellowText={lastWord}
              />
            </Link>
            <div className="category-items-wrap">
              <Row>
                {products.products.length > 0
                  ? products.products.map((product, i) => (
                      <Col sm="12" lg="3" md="6" key={i}>
                        <div
                          className={`prod-item ${
                            product.height === true ? "custom" : ""
                          }`}
                          onClick={() => toggle(product)}
                        >
                          <img
                            src={product.imgSmall}
                            className={`${product.imgHeight ? "custom" : ""}`}
                            alt={product.name}
                          />
                          <h6>{product.name}</h6>
                        </div>
                      </Col>
                    ))
                  : null}
              </Row>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        animation={false}
        id="productModal"
        size="lg"
      >
        <ModalBody>
          <div className="d-flex align-items-center justify-content-end">
            <button className="close btn" onClick={toggle}>
              <img src={closeIcon} width={30} alt="Close" />
            </button>
          </div>
          <div className="product-item-wrap">
            <Row>
              <Col sm="12" lg="6" md="6">
                <img src={value.imgLarge} alt="Sample" />
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="pr-cont">
                  <h4>{value.name}</h4>
                  <h6>{products.category}</h6>
                  {value.points !== true ? (
                    <p>{value.desc}</p>
                  ) : (
                    <ul>
                      {value.pointsData.map((point) => (
                        <li>{point.point}</li>
                      ))}
                    </ul>
                  )}
                  <Link to="/contact">
                    <button className="btn btn-dark">Request a Quote</button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Products;
