import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./Manufacturing.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import SafeEdge from "../../../images/logo/company/manufacturing/safe.png";
import Se_1 from "../../../images/manufacturing/safeedge/se_1.jpg";
import Se_2 from "../../../images/manufacturing/safeedge/se_2.jpg";
import Se_3 from "../../../images/manufacturing/safeedge/se_3.jpg";
import Sedge_1 from "../../../images/manufacturing/safeedge/sed_1.jpg";
import Sedge_2 from "../../../images/manufacturing/safeedge/sed_2.jpg";
import Sedge_3 from "../../../images/manufacturing/safeedge/sed_3.jpg";
import Sedge_4 from "../../../images/manufacturing/safeedge/sed_4.jpg";
import Sedge_5 from "../../../images/manufacturing/safeedge/sed_5.jpg";
import Sedge_6 from "../../../images/manufacturing/safeedge/sed_6.jpg";
import Material from "../../../images/icons/manufacturing/material.svg";
import Recycle from "../../../images/icons/manufacturing/recycle.svg";
import Economical from "../../../images/icons/manufacturing/economical.svg";
import Strength from "../../../images/icons/manufacturing/strength.svg";

// End of Image Imports

function SafeEdgeProducts() {
  const productsData = [
    {
      title: "WRAP-AROUND ANGLEBOARD",
      desc: "The Flexible wrap-around angleboard is easily usable for inside or outside protection.",
      example: "Example: Paper rolls / Steel Coils",
      imgSrc: Sedge_1,
    },
    {
      title: "STRENGTHENING PACKAGING UNITS",
      desc: "The strength of single packages can be improved by reinforcing boxes internally with Angleboards.",
      example: "Example: Delicate / Fragile electronic components",
      imgSrc: Sedge_2,
    },
    {
      title: "VERTICAL PROTECTION",
      desc: "Angleboards stabilise the pallets and provide protection during transport and warehousing.",
      example: "Example of End-User: Agricultural Industry (Fruits, etc.)",
      imgSrc: Sedge_3,
    },
    {
      title: "PROTECTION OF EDGES",
      desc: "Angleboards protect and support products during all handing operations.",
      example: "Example of End-User: Aluminum and Board Industry",
      imgSrc: Sedge_4,
    },
    {
      title: "STACKING STRENGTH",
      desc: "Choosing theright Angleboards for strengthening pallets units will enable you to optimise space by double - stacking.",
      example:
        "Example of End-User: Manufacturers of Household Machines / White Goods",
      imgSrc: Sedge_5,
    },
    {
      title: "EDGE PROTECTORS",
      desc: "Protect products against strapping damage.",
      example: "Example of End-User: Saw Mills, CTL Steel Units",
      imgSrc: Sedge_6,
    },
  ];

  const edgeOptions = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Safe Edge Products | Manufacturing - Southern Packaging`;
  }, []);
  return (
    <section className="manufacturing-common-wrap">
      <div className="manufacturing-section section-padding">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between header-wrap">
            <h3 className="section-header">
              Safe Edge<span> Products</span>
            </h3>
            <img src={SafeEdge} width={220} alt="Pallet" />
          </div>
          <div className="manufacturing-slider-wrap">
            <OwlCarousel className="owl-theme" {...edgeOptions}>
              <div className="item">
                <img src={Se_1} alt="Slider" />
              </div>
              <div className="item">
                <img src={Se_2} alt="Slider" />
              </div>
              <div className="item">
                <img src={Se_3} alt="Slider" />
              </div>
            </OwlCarousel>
          </div>
          <div className="srv-text">
            <p>
              Safe Edge unveils another unique packing concept, Angleboards made
              of multiple plies of laminated paperboard with exceptional
              strength, Angleboard is an effective means to reinforce package
              strength and prevent damage of products during transit. Economical
              and Environment friendly,
            </p>
            <p>
              Angleboards is a superior substitute to costly and inconsistent
              conventional packing material. Angleboard is an ideal way to pack
              your products.
            </p>
          </div>
          <div className="divider"></div>
          <h5>The Safe Edge Range of Angleboard (SEP)</h5>
          <p>
            Since 2008, we have been successfully catering to leading industries
            by providing them ISPM 15 compliant high quality plywood and
            pinewood pallets and boxes. With the profound experience and
            expertise of our team we have set a strong foothold in the industry.
          </p>
          <div className="cpro-wrap">
            <Row>
              <Col sm="12" lg="3" md="3">
                <div className="cpro-item sedge">
                  <h6>Angle Boards (AB)</h6>
                  <p>
                    For Protection of the corners of the product, cut to exact
                    lengths.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="3" md="3">
                <div className="cpro-item sedge">
                  <h6>Edge Protectors (EP)</h6>
                  <p>
                    For - protection against damages associated with strapping.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="3" md="3">
                <div className="cpro-item sedge">
                  <h6>Inner - Dia Protection (ID)</h6>
                  <p>For protection of the Outside wrap - around edges.</p>
                </div>
              </Col>
              <Col sm="12" lg="3" md="3">
                <div className="cpro-item sedge">
                  <h6>Fabricated Sections (FS)</h6>
                  <p>Protection frames for standard or distinct pallets.</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>About Us</h5>
          <p>
            Angleboard is an excellent product because of its properties such as
            flexibility in packaging, It economic fesability, recyclable and
            easy to use yet strong.
          </p>
          <div className="cpro-wrap">
            <Row>
              <Col sm="12" lg="6" md="6">
                <div className="cpro-item abt">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Material} alt="Material" />
                    </div>
                    <h6>Materials</h6>
                  </div>
                  <p>
                    The Raw materials used in Angleboard production are of high
                    quality and enable us to produce strong and durable
                    products.
                  </p>
                  <p>
                    It is suitable for food packaging and can be sent a over the
                    world without any need of extra treatment such as the
                    fumigation required for wooden materials.
                  </p>
                  <p>
                    By using different materials Midfield can find the right
                    quality for all conditions.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="cpro-item abt">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Economical} alt="Economical" />
                    </div>
                    <h6>Economical</h6>
                  </div>
                  <p>
                    By choosing Angleboard in the right size and quality. there
                    is no need to pay for unnecessary materials.
                  </p>
                  <p>
                    Safe Edge Products allow you to optimise your transport
                    space by double stacking.
                  </p>
                  <p>
                    Strengthening your package with Safe Edge Product will
                    minimizes transport damage and is cheaper and lighter than
                    comparable solutions using wood.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="cpro-item abt">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Recycle} alt="Recycle" />
                    </div>
                    <h6>Recyclable</h6>
                  </div>
                  <p>
                    As we our selves recycle 99% of our production waste.
                    recycling is close to our heart.
                  </p>
                  <p>
                    We want to help our customers achieve the same levels of
                    recycling and that's why all our products are 100%
                    recyclable.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="cpro-item abt">
                  <div className="d-flex align-items-center gap-3">
                    <div className="cpro-icon">
                      <img src={Strength} alt="Strength" />
                    </div>
                    <h6>Strength</h6>
                  </div>
                  <p>
                    To find the right product for your purposes. we can test the
                    strapping strength, compression, bending, tear resistance or
                    tensile strength.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <h5>Our Products</h5>
          <div className="sedge-products-wrap">
            <Row>
              {productsData.map((product) => (
                <Col sm="12" lg="6" md="6">
                  <div className="sedge-prod-item">
                    <img src={product.imgSrc} alt={product.title} />
                    <div className="sedge-cont">
                      <h6>{product.title}</h6>
                      <p>{product.desc}</p>
                      <p>{product.example}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Link to="/contact">
            <button className="btn btn-dark mt-4">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default SafeEdgeProducts;
