import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./About.css";

// Image Imports

import Vision from "../../../images/icons/vision.svg";
import Mission from "../../../images/icons/mission.svg";

// End of Image Imports

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About us - Southern Packaging";
  }, []);

  return (
    <section className="about-us-wrap">
      <div className="about-us-section section-padding">
        <div className="container">
          <h3 className="section-header">
            About <span>Us</span>
          </h3>
          <div className="srv-text">
            <p>
              We at Southern Packaging are envisaged to be a one stop packaging
              store, Providing customers with quality products and service,
              Always striving to reduce the customers cost by providing
              innovative solutions and products.
            </p>
            <p>
              Technology, Expertise and the complete packaging solution for all
              your packaging needs. We deal with all packaging material,
              machineries and custom packaging lines as per your business
              requirement.
            </p>
          </div>
          <div className="about-vm-wrap">
            <Row>
              <Col sm="12" lg="6" md="6">
                <div className="vision-content-wrap">
                  <h4>
                    <img src={Vision} alt="Vision" />
                    Vision
                  </h4>
                  <p>
                    Southern Packaging is envisaged to be a one stop packaging
                    store, providing customers with quality products and
                    service, always striving to reduce the customers packaging
                    cost by providing innovative solutions and products.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="mission-content-wrap yellow">
                  <h4>
                    <img src={Mission} alt="Mission" />
                    Mission
                  </h4>
                  <p>
                    <span>
                      To save you money with high quality, competitively priced
                      products.
                    </span>
                    <span>
                      To make purchasing from us an enjoyable experience through
                      our extensive range of products &amp; superior customer
                      service.
                    </span>
                    <span>
                      To provide you with exceptional sales support – before
                      &amp; after the sale.
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
